import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { sendMessage, startChatting } from '../../apis/chatting';
import UserMessage from '../../components/Chat/Message/User';
import BotMessage from '../../components/Chat/Message/Bot';
import Loader from '../../components/Chat/Message/Loader';
import { Flex, Button, Typography } from 'antd';
import { LuRefreshCw } from 'react-icons/lu';
import { RiRobot2Line } from 'react-icons/ri';
import { ArrowUpOutlined } from '@ant-design/icons';
import styles from '../../styles/Chatting.module.css';

export default function Text() {
  const [threadId, setThreadId] = useState('');
  const [currentMessage, setCurrentMessage] = useState('');
  const [messageList, setMessageList] = useState(
    JSON.parse(localStorage.getItem('messageList')) || []
  );
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem('threadId')) {
        const res = await startChatting('text');
        if (res.status === 200) {
          const threadId = res.data.threadId;
          localStorage.setItem('threadId', threadId);
          setThreadId(threadId);
        }
      } else {
        setThreadId(localStorage.getItem('threadId'));
      }
    })();
  }, []);

  const onChange = (e) => {
    setCurrentMessage(e.target.value);
  };

  // PC에서 enter키로 입력하는 로직
  // const onKeyDown = async (e) => {
  //   if (e.key === 'Enter' && !e.shiftKey) {
  //     e.preventDefault();
  //     await onSubmit(e);
  //   }
  // };

  const onSubmit = async (e) => {
    e.preventDefault();

    const query = currentMessage;
    if (!query.trim()) return;

    const userMessage = { type: 'user', text: currentMessage };
    setMessageList((prevMessages) => [...prevMessages, userMessage]);
    setCurrentMessage('');
    setIsLoading(true);

    const res = await sendMessage(threadId, query);
    if (res.status === 200) {
      const botMessage = { type: 'bot', text: res.data.message };
      setMessageList((prevMessages) => [...prevMessages, botMessage]);
      localStorage.setItem(
        'messageList',
        JSON.stringify([...messageList, userMessage, botMessage])
      );
      setIsLoading(false);
    }
  };

  const onRefresh = async () => {
    setMessageList([]);
    localStorage.removeItem('messageList');

    const res = await startChatting('text');
    if (res.status === 200) {
      const threadId = res.data.threadId;

      localStorage.setItem('threadId', threadId);
      setThreadId(threadId);
    }
  };

  return (
    <Flex justify='flex-start' vertical className={styles.container} gap={12}>
      <Flex
        align='center'
        justify='space-between'
        className={styles.alignCenter}
      >
        <Flex align='center' gap={6}>
          <Typography.Text className={styles.title}>AI 챗봇</Typography.Text>
          <Typography.Text className={styles.highlight}>
            채팅 상담
          </Typography.Text>
        </Flex>
        <Button
          onClick={onRefresh}
          type='text'
          icon={<LuRefreshCw className={styles.refreshIcon} />}
        />
      </Flex>
      <Flex className={styles.alignCenter}>
        <div className={styles.window} data-ismobile={isMobile}>
          {messageList.length > 0 &&
            messageList.map((message, i) => (
              <div key={i}>
                {message.type === 'bot' ? (
                  <div className={styles.botArea}>
                    <div className={styles.nameTag}>
                      <RiRobot2Line className={styles.botIcon} />
                    </div>
                    <BotMessage message={message.text} />
                  </div>
                ) : (
                  <UserMessage message={message.text} />
                )}
              </div>
            ))}
          {isLoading && <Loader />}
        </div>
      </Flex>
      <Flex className={styles.alignCenter}>
        <form className={styles.inputArea} onSubmit={onSubmit}>
          <textarea
            className={styles.input}
            onChange={onChange}
            // onKeyDown={onKeyDown}
            value={currentMessage}
            placeholder='메세지를 입력하세요'
          />
          <Button
            type='text'
            htmlType='submit'
            icon={<ArrowUpOutlined style={{ color: 'white' }} />}
            className={styles.sendButton}
          />
        </form>
      </Flex>
    </Flex>
  );
}
