import { Flex, Typography } from 'antd';
import styles from './Message.module.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';
import kakaoLogo from '../../../assets/logo/kakaoLogo.png';
import { IoIosInformationCircleOutline } from 'react-icons/io';

export default function ({ onClick, submitted, isLoading }) {
  const message = `채권 양도에 관한 모든 조항 고지를 마쳤습니다.
아래 버튼을 누르시면 구분 소유자님 명의 전화번호로 <span class="highlight-blue">카카오톡 전자서명</span>을 요청하겠습니다.`;

  const infoMessage = `서명 안내 메시지는 구분소유자의 휴대폰 번호로 발송됩니다.\n세대주(임차인)가 아니라, 반드시 <span class="highlight-blue">'구분소유자'의 휴대전화</span>를 통해 서명을 진행해주세요.`;

  const submitCompleteMessage = <span className='submitted'>제출 완료</span>;
  const submitLoadingMessage = <span>전자 서명 요청중...</span>;
  const submitButtonMessage = (
    <span className='logoBox'>
      <img src={kakaoLogo} alt='카카오톡 로고' />
      채권양도 동의서 전자서명
    </span>
  );

  return (
    <Container>
      <MessageBox>
        <div className={`${styles.markdownWrapper}`}>
          <ReactMarkdown
            children={message}
            components={{
              p: ({ node, ...props }) => (
                <p className={styles.paragraph} {...props} />
              ),
              strong: ({ node, ...props }) => (
                <strong className={styles.strong} {...props} />
              ),
            }}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
        <InfoBox>
          <IoIosInformationCircleOutline size={20} color='#4E3CF4' />
          <InfoText dangerouslySetInnerHTML={{ __html: infoMessage }} />
        </InfoBox>
        <KakaoSignButton>
          <button
            onClick={onClick}
            className={styles.kakaoSignButton}
            disabled={submitted}
          >
            {submitted
              ? submitCompleteMessage
              : isLoading
                ? submitLoadingMessage
                : submitButtonMessage}
          </button>
        </KakaoSignButton>
      </MessageBox>
    </Container>
  );
}

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0px 8px 8px 8px;
  background: #fff;
  word-break: keep-all;
  width: 100%;

  .highlight-blue {
    background: var(--GR_01, linear-gradient(180deg, #5d7cf6 0%, #4e3cf4 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.32px;
  }
  .${styles.markdownWrapper} {
    width: 100%;
  }

  .${styles.markdownWrapper} p {
    word-break: keep-all;
    white-space: pre-wrap;
  }

  p {
    word-break: keep-all !important;
    white-space: pre-wrap !important;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const KakaoSignButton = styled.button`
  width: 100%;
  span {
    color: #3c1e1e;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
  }
  button {
    width: 100%;
  }
  .logoBox {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .submitted {
    color: #fff;
  }
`;

const InfoBox = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  background-color: #f0f2ff;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const InfoText = styled.p`
  color: #4e3cf4;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 8px;
  margin-bottom: 0;
  white-space: pre-wrap;

  .highlight-blue {
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
  }
`;
