import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import Home from '../pages/Home';
import TextChat from '../pages/chat/Text';
import VoiceChat from '../pages/chat/Voice';
import Dashboard from '../pages/Dashboard';
import Members from '../pages/Members';
import Defects from '../pages/Defects';
import Login from '../pages/Login';
import KakaoCallback from './auth/KakaoCallback';
import Signup from '../pages/Signup';
import DocumentPage from '../pages/DocumentPage';
import CounselText from '../pages/counsel/CounselText';
import CounselVoice from '../pages/counsel/CounselVoice';
import LoginCode from '../pages/LoginCode';
import SignupRoute from './SignupRoute';
import AdminPage from '../pages/admin/AdminPage';
import AdminDocument from '../components/Admin/AdminDocument';
import AdminDefects from '../components/Admin/AdminDefects';
import AdminIncidents from '../components/Admin/AdminIncidents';
import AdminIncident from '../pages/admin/AdminIncident';
import PaymentTest from '../pages/PaymentTest';
import Chat from '../pages/paymentReview/Chat';
import PaymentLogin from '../pages/paymentReview/PaymentLogin';
import PaymentRoute from './PaymentRoute';
import PaymentSuccess from '../pages/paymentReview/PaymentSuccess';
import TermsAndConditions from '../pages/paymentReview/TermsAndConditions';
import PrivacyPolicy from '../pages/paymentReview/PrivacyPolicy';
import RefundPolicy from '../pages/paymentReview/RefundPolicy';
import SignupV2 from '../pages/SignupV2';
import HomeV2 from '../pages/HomeV2';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoute adminRoute={'true'} />}>
          <Route path='/:prefix/dashboard' element={<Dashboard />} />
          <Route path='/:prefix/admin' element={<AdminPage />}>
            <Route path='document' element={<AdminDocument />} />
            <Route path='defects' element={<AdminDefects />} />
            <Route path='incidents' element={<AdminIncidents />} />
          </Route>
          <Route
            path='/:prefix/admin/incident/:incidentId'
            element={<AdminIncident />}
          />
        </Route>

        {/* 결제 심사 신청용 */}

        {/* <Route element={<PaymentRoute />}>
          <Route path='/chat' element={<Chat />} />
          <Route path='/success' element={<PaymentSuccess />} />
        </Route>
        <Route path='/signin' element={<PaymentLogin />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/' element={<PaymentLogin />} /> */}

        <Route element={<PrivateRoute />}>
          <Route path='/:prefix/paymentTest' element={<PaymentTest />} />
          <Route path='/:prefix/members' element={<Members />} />
          <Route path='/:prefix/chat/text' element={<TextChat />} />
          <Route path='/:prefix/chat/voice' element={<VoiceChat />} />
          <Route path='/:prefix/counsel/voice' element={<CounselVoice />} />
          <Route path='/:prefix/counsel/text' element={<CounselText />} />
          <Route path='/:prefix/document' element={<DocumentPage />} />
          <Route path='/:prefix/defects' element={<Defects />} />
          <Route path='/:prefix/home' element={<HomeWrapper />} />
          <Route path='/:prefix' element={<Navigate to={'home'} />} />
        </Route>
        <Route path='/:prefix/signup' element={<SignupWrapper />} />

        <Route path='/loginCode' element={<LoginCode />} />
        <Route path='/:prefix/login' element={<Login />} />
        <Route path='/auth/kakao/callback' element={<KakaoCallback />} />
      </Routes>
    </BrowserRouter>
  );
}

function SignupWrapper() {
  const { prefix } = useParams();
  console.log('prefix : ', prefix);

  // if (prefix === 'gran-city-xi1' || prefix === 'thesharp-centralpark3') {
  //   return <SignupV2 />;
  // }

  return <SignupV2 />;
}

function HomeWrapper() {
  const { prefix } = useParams();

  // if (prefix === 'gran-city-xi1' || prefix === 'thesharp-centralpark3') {
  //   return <HomeV2 />;
  // }

  return <HomeV2 />;
}

export default Router;
