import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { pdfjs } from 'react-pdf';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
if (!window.MediaRecorder) {
  window.MediaRecorder = AudioRecorder;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

// 로그 안남게 (배포환경에서만)
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
  // </React.StrictMode>
);
