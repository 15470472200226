import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { apartmentStateAtom, userStateAtom } from '../store/atom';
import { Flex, message, Typography } from 'antd';
import textIcon from '../assets/icon/textIcon.png';
import voiceIcon from '../assets/icon/voiceIcon.png';
import consentIcon from '../assets/icon/consentIcon.png';
import investigationIcon from '../assets/icon/investigationIcon.png';
import styled from 'styled-components';

export default function Home() {
  const navigate = useNavigate();
  const [apartmentState] = useRecoilState(apartmentStateAtom);
  const [userState] = useRecoilState(userStateAtom);
  console.log(userState);
  const isAdmin = userState.role === 'admin';

  const startDefectInvestigation = () => {
    if (apartmentState.prefix === 'songpa-heliocity') {
      window.open('http://pf.kakao.com/_hPxdBG/chat', '_blank');
    } else if (apartmentState.prefix === 'songdo-centralcity') {
      window.open('http://pf.kakao.com/_TxhneG/chat', '_blank');
    } else {
      message.error('등록되지 않은 아파트 입니다.');
    }
  };

  return (
    <Container>
      <AlignCenter>
        <ChattingLinkButton
          onClick={() => navigate(`/${apartmentState.prefix}/counsel/text`)}
        >
          <Icon src={textIcon} alt='문자형 AI 법률 상담' />
          <LinkText white marginTop='12px'>
            <strong>문자형</strong> AI
            <br />
            법률 상담
          </LinkText>
        </ChattingLinkButton>
        <ChattingLinkButton
          onClick={() => navigate(`/${apartmentState.prefix}/counsel/voice`)}
        >
          <Icon src={voiceIcon} alt='대화형 AI 법률 상담' />
          <LinkText white marginTop='12px'>
            <strong>대화형</strong> AI
            <br />
            법률 상담
          </LinkText>
        </ChattingLinkButton>
      </AlignCenter>
      <AlignCenter style={{ flexDirection: 'column' }}>
        <LinkButton
          onClick={() => navigate(`/${apartmentState.prefix}/document`)}
        >
          {userState.consentForm && userState.idCard ? (
            <>
              <Icon
                src={consentIcon}
                alt='채권 양도 양수 동의서 및 신분증 제출'
              />
              <CompletedText style={{ marginLeft: '10px' }}>
                채권 양도 양수
                <br />
                동의서 및 신분증 제출 완료!
              </CompletedText>
            </>
          ) : (
            <>
              <Icon
                src={consentIcon}
                alt='채권 양도 양수 동의서 및 신분증 제출'
              />
              <LinkText style={{ marginLeft: '10px' }}>
                채권 양도 양수
                <br />
                동의서 및 신분증 제출
              </LinkText>
            </>
          )}
        </LinkButton>
        <AdminButton
          onClick={() => navigate(`/${apartmentState.prefix}/admin/document`)}
        >
          <LinkText>동의서 및 신분증 제출 내역 확인</LinkText>
        </AdminButton>
        <LinkButton onClick={startDefectInvestigation}>
          <Icon src={investigationIcon} alt='하자조사 시작하기' width='50px' />
          <LinkText style={{ marginLeft: '40px' }}>하자조사 시작하기</LinkText>
        </LinkButton>
        <AdminButton
          onClick={() => navigate(`/${apartmentState.prefix}/defects`)}
        >
          <LinkText>하자조사 접수 확인</LinkText>
        </AdminButton>
      </AlignCenter>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  font-family: 'Pretendard', sans-serif;
  background-color: #fff;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
`;

const AlignCenter = styled.div`
  margin: 0 20px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
`;

const ChattingLinkButton = styled(ButtonBase)`
  height: 200px;
  flex-direction: column;
  background-color: #00285b;
  box-shadow: 0px 0px 20px 0px rgba(139, 160, 177, 0.3);
`;

const LinkButton = styled(ButtonBase)`
  height: 100px;
  background-color: #c1dbef;
`;

const LinkText = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 100%;
  color: ${(props) => (props.white ? '#fff' : '#000')};
  margin-top: ${(props) => props.marginTop || '0'};
  text-align: center;

  strong {
    font-weight: 700;
  }
`;

const Icon = styled.img`
  width: ${(props) => props.width || '67px'};
`;

const CompletedText = styled(LinkText)`
  /* color: #00635a; */
`;

const AdminButton = styled(LinkButton)`
  background-color: #dadada;
  height: 80px;
`;
