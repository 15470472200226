import { privateApi } from '.';

export const getIncidents = async ({ apartmentId, status, page, limit }) => {
  return await (
    await privateApi.get('/incident', {
      params: {
        apartmentId,
        status,
        page,
        limit,
      },
    })
  ).data;
};

export const getIncidentDetailApi = async (id) => {
  return await (
    await privateApi.get(`/incident/detail/${id}`)
  ).data;
};

export const assignLawyer = async ({ incidentId, lawyerId }) => {
  return await await privateApi.put(`/incident/assign-lawyer/${incidentId}`, {
    lawyerId,
  });
};
