import React, { useState } from 'react';
import { Modal, Button, Typography, Space, Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import chatIcon from '../assets/icon/chatIcon.png';
import ReactMarkdown from 'react-markdown';

const { Title, Paragraph } = Typography;

const ChatDownloadComponent = ({ chatHistory }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDownload = () => {
    const element = document.getElementById('modal-content');
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'chat-history.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  // chatHistory의 요소 중 role: "assistant" && content: ""인 요소를 제거
  chatHistory = chatHistory?.filter(
    (chat) => chat.role !== 'assistant' || chat.content !== ''
  );

  return (
    <>
      <FooterBtn onClick={showModal}>
        <img src={chatIcon} alt='chatDownload' />
        <p>채팅 기록 다운 받기</p>
      </FooterBtn>
      <Modal
        title={
          <Title level={3} style={{ color: '#1D6DA8', padding: '0 20px' }}>
            채팅 기록
          </Title>
        }
        styles={{
          body: {
            // height: '90vh',
            // overflowY: 'scroll',
          },
        }}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={700}
        style={{
          padding: 15,
          // height: '90vh',
          // overflowY: 'auto',
        }}
      >
        <div id='modal-content' style={{ padding: '10px 20px' }}>
          {chatHistory?.map((chat, index) => (
            <React.Fragment key={index}>
              <Paragraph
                style={{
                  marginBottom: 0,
                }}
              >
                <strong>{chat.role === 'user' ? '내담자' : 'AI'}:</strong>{' '}
                <ReactMarkdown className={'markdown-reset'}>
                  {chat.content}
                </ReactMarkdown>
              </Paragraph>
              {index < chatHistory.length - 1 && (
                <Divider
                  style={{
                    margin: 5,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <Button
          icon={<DownloadOutlined />}
          onClick={handleDownload}
          style={{ marginTop: '20px', background: '#1D6DA8', color: 'white' }}
        >
          다운로드
        </Button>
      </Modal>
    </>
  );
};

const FooterBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  border: 2px solid #f0f0f0;
  padding: 12px 25px 12px 65px;
  margin-bottom: 16px;
  border-radius: 12px;
  cursor: pointer;
  img {
    width: 24px;
    margin-right: 12px;
  }
`;

const CustomModal = styled(Modal)``;

export default ChatDownloadComponent;
