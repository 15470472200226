import { useState, useEffect } from 'react';
import { getDefectImages } from '../apis/defects';
import DefectsAlbum from '../components/DefectsAlbum';
import { Flex, Typography } from 'antd';
import styles from '../styles/Defects.module.css';
import ChatHeader from '../components/common/ChatHeader';

export default function Defects() {
  const [count, setCount] = useState({});
  const [list, setList] = useState({});

  useEffect(() => {
    (async () => {
      const { data } = await getDefectImages();
      setCount(data.count);
      setList(data.list);
    })();
  }, []);

  console.log('count : ', count);
  console.log('list : ', list);

  return (
    <Flex justify='flex-start' vertical className={styles.container} gap={24}>
      {/* Tile */}
      <ChatHeader
        title={'하자 조사 접수 확인'}
        hideRefresh={true}
        isDocumentPage={true}
      />
      <Flex vertical gap={10} className={styles.window}>
        <Typography.Text
          className={styles.category}
        >{`타일 조사 : 총 ${count?.tile || 0}건`}</Typography.Text>
        <DefectsAlbum media={list.tile} />
      </Flex>
      {/* Wood */}
      <Flex vertical gap={10}>
        <Typography.Text
          className={styles.category}
        >{`목재 조사 : 총 ${count?.wood || 0}건`}</Typography.Text>
        <DefectsAlbum media={list.wood} />
      </Flex>
      {/* Crack */}
      <Flex vertical gap={10}>
        <Typography.Text
          className={styles.category}
        >{`균열 조사 : 총 ${count?.crack || 0}건`}</Typography.Text>
        <DefectsAlbum media={list.crack} />
      </Flex>
      {/* Appliance */}
      <Flex vertical gap={10}>
        <Typography.Text
          className={styles.category}
        >{`기기장치 조사 : 총 ${count?.appliance || 0}건`}</Typography.Text>
        <DefectsAlbum media={list.appliance} />
      </Flex>
      {/* Condensation */}
      <Flex vertical gap={10}>
        <Typography.Text
          className={styles.category}
        >{`누수 조사 : 총 ${count?.condensation || 0}건`}</Typography.Text>
        <DefectsAlbum media={list.condensation} />
      </Flex>
      {/* Insulation */}
      <Flex vertical gap={10}>
        <Typography.Text
          className={styles.category}
        >{`단열 조사 : 총 ${count?.insulation || 0}건`}</Typography.Text>
        <DefectsAlbum media={list.insulation} />
      </Flex>
      {/* ETC */}
      <Flex vertical gap={10}>
        <Typography.Text
          className={styles.category}
        >{`기타 조사 : 총 ${count?.etc || 0}건`}</Typography.Text>
        <DefectsAlbum media={list.etc} />
      </Flex>
    </Flex>
  );
}
