import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { apartmentStateAtom } from '../store/atom';
import { useMediaQuery } from 'react-responsive';
import { getStatistics } from '../apis/dashboard';
import StatisticsItem from '../components/Dashboard/StatisticsItem';
import Chart from '../components/Dashboard/Chart';
import { Flex } from 'antd';
import styles from '../styles/Dashboard.module.css';
import ChatHeader from '../components/common/ChatHeader';
import styled from 'styled-components';

export default function Dashboard() {
  const [apartmentState] = useRecoilState(apartmentStateAtom);
  const [data, setData] = useState({});
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  useEffect(() => {
    if (apartmentState.id) {
      (async () => {
        const res = await getStatistics(apartmentState.id);
        setData(res.data);
      })();
    }
  }, [apartmentState]);

  console.log('data : ', data);

  return (
    <Container>
      <ChatHeader title={'대시보드'} hideRefresh={true} />
      <Window vertical gap={20}>
        <Flex align='center' gap={8} className={styles.alignCenter}>
          <StatisticsItem
            label='총 참여율'
            value={data.participationRate?.toFixed(1)}
            unit='%'
          />
          <StatisticsItem
            label='접수된 하자 건수'
            value={data.totalDefectsCount}
            unit='건'
          />
          <StatisticsItem
            label='챗봇 상담 건수'
            value={data.totalChatCount}
            unit='건'
          />
        </Flex>
        <Flex
          align='center'
          data-ismobile={isMobile}
          className={`${styles.alignCenter} ${styles.chart}`}
        >
          <Chart data={data.defectsByBuildingBlock} />
        </Flex>
      </Window>
    </Container>
  );
}

const Container = styled.div`
  overflow-y: hidden;
  background-color: #dde5ea;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
const Footer = styled(Flex)`
  margin: 0;
  box-sizing: border-box;
  padding: 16px 20px;
  height: 80px;
  background-color: #abbecd;
  width: 100%;
  z-index: 9;
  /* position: fixed;
  bottom: 0; */
  @media screen and (min-width: 768px) {
    width: calc(100% - 240px);
  }
`;
const Window = styled(Flex)`
  /* background-color: red; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;

  overflow: auto;
  padding: 32px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;
