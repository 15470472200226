import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import userProfileIcon from '../../../assets/icon/userProfileIcon.png';

export default function UserProfile({ userState, isAdmin }) {
  const { prefix } = useParams();

  const onClickLogout = () => {
    console.log('로그아웃');
    localStorage.removeItem('accessTokenV2');
    alert('로그아웃 되었습니다.');
    window.location.href = `/${prefix}/login`;
  };

  return (
    <UserProfileWrapper>
      <UserInfoWrapper>
        {isAdmin && (
          <img src={userProfileIcon} alt='userProfileIcon' width={24} />
        )}
        <Address
          isAdmin={isAdmin}
        >{`${userState.buildingBlock}동 ${userState.unit}호`}</Address>
        <UserInfoWrapper>
          <Name isAdmin={isAdmin}>{userState.name}</Name>
          <LogoutButton isAdmin={isAdmin} onClick={onClickLogout}>
            로그아웃
          </LogoutButton>
        </UserInfoWrapper>
      </UserInfoWrapper>
    </UserProfileWrapper>
  );
}

const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;

  font-size: 15px;

  * {
    line-height: 100%;
  }
`;
const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const Address = styled.span`
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  ${(props) =>
    props.isAdmin &&
    `
    color: #000;
  `}
`;

const Name = styled.span`
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  margin-right: 6px;
  ${(props) =>
    props.isAdmin &&
    `
    color: #000;
  `}
`;

const LogoutButton = styled.button`
  display: flex;
  height: 20px;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #fbfbfb;
  color: #00285b;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  border: none;
  cursor: pointer;
  ${(props) =>
    props.isAdmin &&
    `
    display: flex;
height: 20px;
padding: 2px 4px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 4px;
background: #BDBDBD;
overflow: hidden;
color: #FFF;
text-overflow: ellipsis;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
  `}
`;
