import { publicApi } from '.';

export const getApartmentInfo = async (prefix) =>
  await publicApi.get(`/apartment/${prefix}`, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
