import React from 'react';
import { Typography, Card, List } from 'antd';

const { Title, Paragraph } = Typography;

function RefundPolicy() {
  const POLICY_CONTENT = `회원은 각 서비스에 대한 계약을 체결하고 이용금액을 입금한 후 담당변호사가 소송업무(사실관계 정리, 증거자료 수집, 정리를 포함)에 착수하기 전까지는 계약을 해제하고 환불을 받을 수 있습니다.

그러나 담당변호사가 소송업무에 착수한 경우라면 원칙적으로 계약해제와 환불이 불가하며, 업무 수행 정도에 비례하여 아래와 같이 환불이 가능합니다.

- 소장/답변서/준비서면 등 작성 중 : 50% 환불 가능
- 채권추심 : 착수금은 환불 불가`;

  const policyItems = [
    '소장/답변서/준비서면 등 작성 중 : 50% 환불 가능',
    '채권추심 : 착수금은 환불 불가',
  ];

  return (
    <Card
      title={<Title level={2}>환불 규정 (법무법인 더 에이치 황해)</Title>}
      style={{ maxWidth: 800, margin: '0 auto' }}
    >
      <Paragraph>
        회원은 각 서비스에 대한 계약을 체결하고 이용금액을 입금한 후
        담당변호사가 소송업무(사실관계 정리, 증거자료 수집, 정리를 포함)에
        착수하기 전까지는 계약을 해제하고 환불을 받을 수 있습니다.
      </Paragraph>
      <Paragraph>
        그러나 담당변호사가 소송업무에 착수한 경우라면 원칙적으로 계약해제와
        환불이 불가하며, 업무 수행 정도에 비례하여 아래와 같이 환불이
        가능합니다.
      </Paragraph>
      <List
        dataSource={policyItems}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text>{item}</Typography.Text>
          </List.Item>
        )}
      />
    </Card>
  );
}

export default RefundPolicy;
