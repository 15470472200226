import { useParams, useSearchParams } from 'react-router-dom';
import { Flex, Typography } from 'antd';
import Logo from '../assets/icon/theh-logo.png';
import textLogo from '../assets/icon/theh-logo-text.png';
import KakaoLoginButton from '../assets/icon/kakaoLogin.png';
import styles from '../styles/Login.module.css';

export default function Login() {
  const { prefix } = useParams();
  localStorage.setItem('prefix', prefix);

  const kakaoLoginUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_RESTAPI_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_CALLBACK_URL}&response_type=code&state=${prefix}`;

  return (
    <Flex
      vertical
      align='center'
      justify='center'
      className={styles.container}
      style={{
        padding: '310px 0 283px',
        boxSizing: 'border-box',
      }}
    >
      <Flex vertical align='center' gap={12} className={styles.logoGroup}>
        <img src={Logo} alt='theh logo' width={260} />
        {/* line */}
        <div
          style={{
            width: '276px',
            height: '1px',
            backgroundColor: '#C9C9C9',
            margin: '5px 0 69px',
          }}
        />
        {/* <img src={textLogo} alt='하자소송 AI 플랫폼' width={212} /> */}
      </Flex>
      <a href={kakaoLoginUrl}>
        <img src={KakaoLoginButton} alt='Kakao login button' width={320} />
      </a>
    </Flex>
  );
}
