import { BeatLoader } from 'react-spinners';

export default function Loader() {
  return (
    <BeatLoader
      color='#1C5A91'
      style={{
        marginTop: '1rem',
      }}
    />
  );
}
