import { Flex, Typography, Input, message } from 'antd';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isRegisteredAtom } from '../store/atom';

export default function LoginCode() {
  const navigate = useNavigate();

  const [authCode, setAuthCode] = useState('');
  const [isRegistered, setIsRegistered] = useRecoilState(isRegisteredAtom);
  // console.log(isRegistered);

  const onClickNext = () => {
    if (authCode === '헬리오') {
      if (isRegistered) {
        return navigate('/songpa-heliocity/home');
      } else {
        return navigate('/songpa-heliocity/signup');
      }
    } else if (authCode === '센트럴') {
      if (isRegistered) {
        return navigate('/songpa-parkcentral/home');
      } else {
        return navigate('/songpa-parkcentral/signup');
      }
    }
    // 관리자 페이지
    else if (authCode === '더에이치-헬리오') {
      if (isRegistered) {
        return navigate('/songpa-heliocity/admin/document');
      } else {
        return navigate('/songpa-heliocity/signup');
      }
    } else if (authCode === '더에이치-센트럴') {
      if (isRegistered) {
        return navigate('/songpa-parkcentral/admin/document');
      } else {
        return navigate('/songpa-parkcentral/signup');
      }
    }
    // 일치하지 않는 경우
    else {
      message.error('인증코드가 일치하지 않습니다. 인증코드를 확인해주세요.');
    }
  };

  return (
    <Container vertical align='center' justify='center'>
      <AuthCode>
        <h3>인증 코드</h3>
        <StyledInput
          type='text'
          value={authCode}
          onChange={(e) => setAuthCode(e.target.value)}
        />
      </AuthCode>
      <NextBtn onClick={onClickNext}>
        <p>다음</p>
      </NextBtn>
    </Container>
  );
}

const Container = styled(Flex)`
  box-sizing: border-box;
  height: 100vh;
  padding: 0 20px;
  width: 100%;
`;

const AuthCode = styled.div`
  width: 100%;
  h3 {
    color: #5f5f5f;
    font-size: 20px;
    margin-bottom: 4px;
    line-height: 1.4;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  border: 1px solid #676767;
  height: 48px;
  margin-bottom: 51px;
  font-size: 16px;
  &:focus {
    border-color: #006fc1;
  }
`;

const NextBtn = styled.button`
  background-color: var(--main-color);
  width: 100%;
  height: 48px;
  border-radius: 8px;
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
`;
