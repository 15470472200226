import { useRecoilState } from 'recoil';
import { userStateAtom } from '../../store/atom';
import { useMediaQuery } from 'react-responsive';
import MobileLayout from './Mobile';
import DesktopLayout from './Desktop';
import { useLocation } from 'react-router-dom';

export default function ({ isAdmin, prefix, children }) {
  const [userState] = useRecoilState(userStateAtom);
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  const location = useLocation();
  // console.log(location.pathname);
  const hideHeader =
    (!location.pathname.includes('/home') &&
      !location.pathname.includes('/admin')) ||
    // 사건 상세보기 페이지는 헤더 숨기기
    location.pathname.includes('/admin/incident/');
  console.log(hideHeader);

  return isMobile ? (
    <MobileLayout
      userState={userState}
      isAdmin={isAdmin}
      prefix={prefix}
      hideHeader={hideHeader}
    >
      {children}
    </MobileLayout>
  ) : (
    <DesktopLayout userState={userState} isAdmin={isAdmin} prefix={prefix}>
      {children}
    </DesktopLayout>
  );
}
