import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../UserProfile';
import { Flex, Button, Typography, Drawer, message } from 'antd';
import { MenuOutlined, AppstoreFilled, TeamOutlined } from '@ant-design/icons';
import styles from './Mobile.module.css';
import Logo from '../../../assets/logo/theh_logo.png';
import styled from 'styled-components';

export default function Index({
  userState,
  isAdmin,
  prefix,
  children,
  hideHeader,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const isSubmitted = userState.consentForm && userState.idCard;
  const openSidebar = () => {
    setOpen(true);
  };
  console.log({ isAdmin });

  const closeSidebar = () => {
    setOpen(false);
  };

  const moveToPage = (page) => {
    navigate(page);
    closeSidebar();
  };

  const startDefectInvestigation = () => {
    if (prefix === 'songpa-heliocity') {
      window.open('http://pf.kakao.com/_hPxdBG/chat', '_blank');
    } else if (prefix === 'songdo-centralcity') {
      window.open('http://pf.kakao.com/_TxhneG/chat', '_blank');
    } else {
      message.error('등록되지 않은 아파트 입니다.');
    }
  };

  if (hideHeader) {
    return <>{children}</>;
  }

  return (
    <MobileContainer>
      <MobileHeader isAdmin={isAdmin}>
        <StyledButton onClick={openSidebar} marginLeft='12px' opacity='0'>
          <MenuOutlined style={{ fontSize: 20 }} />
        </StyledButton>
        <UserProfile isAdmin={isAdmin} userState={userState} />
      </MobileHeader>
      <Body>{children}</Body>
      {/* 사이드 메뉴 */}
      {/* <Drawer
        placement='left'
        closable={false}
        open={open}
        onClose={closeSidebar}
        key='left'
        width={240}
        classNames={{ body: styles.drawerBody }}
      >
        <button
          className={styles.homeButton}
          onClick={() => moveToPage(`/${prefix}/home`)}
        >
          <img src={Logo} alt='logo' width={200} />
        </button>
        {isAdmin && (
          <Flex vertical gap={8} style={{ marginBottom: 16 }}>
            <StyledButton
              type='text'
              className={styles.dashboardButton}
              // onClick={() => moveToPage(`/${prefix}/dashboard`)}
              onClick={() => moveToPage(`/${prefix}/admin/document`)}
            >
              <AppstoreFilled className={styles.dashboardIcon} />
              관리자 페이지
            </StyledButton>
          </Flex>
        )}
        <Flex vertical gap={20}>
          <Flex vertical align='flex-start'>
            <Typography className={styles.category}>AI 법률 상담</Typography>
            <StyledButton
              type='text'
              onClick={() => moveToPage(`/${prefix}/counsel/text`)}
            >
              문자형 AI 법률 상담
            </StyledButton>
            <StyledButton
              type='text'
              onClick={() => moveToPage(`/${prefix}/counsel/voice`)}
            >
              대화형 AI 법률 상담
            </StyledButton>
          </Flex>
          <Flex vertical align='flex-start'>
            <Typography className={styles.category}>
              하자 소송 서류 접수
            </Typography>
            <StyledButton
              type='text'
              onClick={() => {
                moveToPage(`/${prefix}/document`);
              }}
              className={isSubmitted && styles.submitButton}
            >
              {isSubmitted
                ? '채권 양도 동의서, 신분증 제출 완료'
                : '채권 양도 동의서, 신분증 제출'}
            </StyledButton>
            <StyledButton
              type='text'
              onClick={() => {
                navigate(`/${prefix}/admin/document`);
              }}
            >
              동의서 및 신분증 제출 내역 확인
            </StyledButton>
          </Flex>
          <Flex vertical align='flex-start'>
            <Typography className={styles.category}>하자조사</Typography>
            <StyledButton type='text' onClick={startDefectInvestigation}>
              하자조사 시작하기
            </StyledButton>
            <StyledButton
              type='text'
              onClick={() => moveToPage(`/${prefix}/defects`)}
            >
              하자조사 접수 확인
            </StyledButton>
          </Flex>
        </Flex>
      </Drawer> */}
    </MobileContainer>
  );
}

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  font-family: 'Pretendard', sans-serif;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MobileHeader = styled.div`
  width: 100%;
  top: 0;
  background-color: #00285b;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  /* isAdmin일때 스타일 정의 */
  ${(props) =>
    props.isAdmin &&
    `
    background-color: #fff;
  `}
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled.button`
  padding: 0;
  font-weight: 400;
  font-family: 'Pretendard', sans-serif;
  font-size: 15px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.color || 'inherit'};
  margin-left: ${(props) => props.marginLeft || '0'};
  opacity: ${(props) => props.opacity || '1'};
`;

const DrawerBody = styled.div`
  padding: 16px !important;
`;

const Category = styled.h3`
  font-size: 17px;
  font-weight: 600;
  color: #000;
  margin-bottom: 3px;
`;

const HomeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  background: none;
  border: none;
  cursor: pointer;
`;

const DashboardButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 100%;
`;

const DashboardIcon = styled(AppstoreFilled)`
  font-size: 22px;
  color: #1c5a91;
`;

const SubmitButton = styled(StyledButton)`
  color: ${(props) => (props.isSubmitted ? '#00be29' : 'inherit')};
`;
