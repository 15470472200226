import { privateApi } from '.';

export const streamChatMessages = async (
  messageList,
  query,
  onMessage,
  onFinish,
  apartmentId
) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/counsel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({ messageList, query, apartmentId }),
  });

  if (response.status === 200) {
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('SIGNAL');
      // console.log('하하하하하  ', lines);
      for (const line of lines.slice(0, -1)) {
        if (line.startsWith('data: ')) {
          const message = line.slice('data: '.length);
          onMessage(message, false);
        }
        if (line.startsWith('stream finished')) {
          onFinish();
        }
      }
      buffer = lines[lines.length - 1];
    }
  } else {
    console.error('Failed to fetch stream');
  }
};

export const speechCounsel = async (formData) => {
  return await privateApi.post(`/speech/counsel`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
