import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { speech, startChatting } from '../../apis/chatting';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import UserMessage from '../../components/Chat/Message/User';
import BotMessage from '../../components/Chat/Message/Bot';
import Loader from '../../components/Chat/Message/Loader';
import { Flex, Button, Typography } from 'antd';
import { LuRefreshCw } from 'react-icons/lu';
import { RiRobot2Line } from 'react-icons/ri';
import { FaMicrophoneAlt } from 'react-icons/fa';
import { FaRegStopCircle } from 'react-icons/fa';
import styles from '../../styles/Chatting.module.css';

export default function Voice() {
  const [voiceThreadId, setThreadId] = useState('');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audioChunks = [];
  const [audioFileList, setFileList] = useState(
    JSON.parse(localStorage.getItem('audioFileList')) || []
  ); // type, audioUrl, text
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem('voiceThreadId')) {
        const res = await startChatting('voice');
        if (res.status === 200) {
          const threadId = res.data.threadId;
          localStorage.setItem('voiceThreadId', threadId);
          setThreadId(threadId);
        }
      } else {
        setThreadId(localStorage.getItem('voiceThreadId'));
      }
    })();
  }, []);

  const handleVoiceRecord = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newMediaRecorder = new MediaRecorder(stream);
      setIsRecording(true); // 녹음 시작 상태로 설정

      newMediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
        const formData = new FormData();
        formData.append('dir', 'audio');
        formData.append('audio', audioBlob, 'audioFile.mp3');
        const userMessage = {
          type: 'user',
          audioUrl: URL.createObjectURL(audioBlob),
          text: '',
        };
        setFileList([...audioFileList, userMessage]);
        localStorage.setItem(
          'audioFileList',
          JSON.stringify([...audioFileList, userMessage])
        );

        try {
          setIsLoading(true);
          const res = await speech(voiceThreadId, formData);
          if (res.status === 200) {
            const botMessage = {
              type: 'bot',
              audioUrl: res.data.ttsAudioLink,
              text: res.data.message,
            };
            userMessage.audioUrl = res.data.sttAudiosLink;
            userMessage.text = res.data.query;
            setFileList([...audioFileList, userMessage, botMessage]);
            localStorage.setItem(
              'audioFileList',
              JSON.stringify([...audioFileList, userMessage, botMessage])
            );
          }
          console.log(res);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      newMediaRecorder.ondataavailable = function (event) {
        audioChunks.push(event.data);
      };

      newMediaRecorder.start();
      setMediaRecorder(newMediaRecorder); // 미디어 레코더 상태 업데이트
      console.log('녹음이 시작되었습니다.');
      console.log(mediaRecorder);
    } catch (error) {
      if (error.name === 'NotFoundError') {
        alert('요청하신 장치를 찾을 수 없습니다.');
      } else {
        console.error(error);
      }
    }
  };

  // 녹음을 중지하고 API 호출하는 함수
  const stopRecordingAndSend = async () => {
    console.log('stopRecordingAndSend');
    console.log(mediaRecorder);
    if (!mediaRecorder || !isRecording) return;

    mediaRecorder.stop(); // 녹음 중지
    setIsRecording(false); // 녹음 상태 업데이트
  };

  const onRefresh = async () => {
    setFileList([]);
    localStorage.removeItem('audioFileList');

    const res = await startChatting('voice');
    if (res.status === 200) {
      const threadId = res.data.threadId;

      localStorage.setItem('voiceThreadId', threadId);
      setThreadId(threadId);
    }
  };

  return (
    <Flex justify='flex-start' vertical className={styles.container} gap={12}>
      <Flex
        align='center'
        justify='space-between'
        className={styles.alignCenter}
      >
        <Flex align='center' gap={6}>
          <Typography.Text className={styles.title}>AI 챗봇</Typography.Text>
          <Typography.Text className={styles.highlight}>
            음성 상담
          </Typography.Text>
        </Flex>
        <Button
          onClick={onRefresh}
          type='text'
          icon={<LuRefreshCw className={styles.refreshIcon} />}
        />
      </Flex>
      <Flex className={styles.alignCenter}>
        <div className={styles.window} data-ismobile={isMobile}>
          {audioFileList.length > 0 &&
            audioFileList.map((file, i) => (
              <div key={i}>
                {file.type === 'bot' ? (
                  <div className={styles.botArea}>
                    <div className={styles.nameTag}>
                      <RiRobot2Line className={styles.botIcon} />
                    </div>
                    <div className={styles.botWrapper}>
                      <AudioPlayer
                        autoPlay={audioFileList.length - 1 === i}
                        src={file.audioUrl}
                        showJumpControls={false}
                        showDownloadProgress={false}
                        showFilledProgress={false}
                        showFilledVolume={false}
                        showSkipControls={false}
                        hasDefaultKeyBindings={false}
                        layout='horizontal-reverse'
                        customProgressBarSection={['DURATION']}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        className={styles.botAudioPlayer}
                        style={{ backgroundColor: '#f0f0f0' }}
                      />
                      {file.text && file?.text.length > 0 && (
                        <BotMessage message={file.text} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.userArea}>
                    <div className={styles.userWrapper}>
                      <AudioPlayer
                        autoPlayAfterSrcChange={false}
                        src={file.audioUrl}
                        showJumpControls={false}
                        showDownloadProgress={false}
                        showFilledProgress={false}
                        showFilledVolume={false}
                        showSkipControls={false}
                        hasDefaultKeyBindings={false}
                        layout='horizontal-reverse'
                        customProgressBarSection={['DURATION']}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        className={styles.userAudioPlayer}
                        style={{ backgroundColor: '#1C5A91', color: 'white' }}
                      />
                      {file.text && file?.text.length > 0 && (
                        <div style={{ width: '25rem' }}>
                          <UserMessage message={file.text} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          {isLoading && <Loader />}
        </div>
      </Flex>

      <Flex align='center' justify='center' className={styles.alignCenter}>
        {isRecording ? (
          <Button
            type='primary'
            icon={<FaRegStopCircle style={{ fontSize: 24 }} />}
            className={styles.recordingButton}
            onClick={stopRecordingAndSend}
          />
        ) : (
          <Button
            type='primary'
            icon={<FaMicrophoneAlt style={{ fontSize: 24 }} />}
            className={styles.recordingButton}
            onClick={handleVoiceRecord}
          />
        )}
      </Flex>
    </Flex>
  );
}
