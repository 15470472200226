import React from 'react';
import { Typography, Card, Divider } from 'antd';

const { Title, Paragraph } = Typography;

function TermsAndConditions() {
  const TERMS_AND_CONDITIONS = `이용약관
 
제1조 (목적)
본 약관은 법무법인 더 에이치(이하 "회사"라 함)가 운영하는 AI 기반 법률 지원 웹사이트 'AI비서 더 에이치'(이하 "사이트"라 함)의 서비스 이용과 관련하여 회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
 
제2조 (정의)
사이트에서 사용하는 주요 용어의 정의는 다음과 같습니다.
① "서비스"라 함은 이용자가 단말기(PC, 휴대기기 등)를 통해 접속하여 이용할 수 있는 회사의 웹사이트에서 제공하는 소액사건 관련 법률 정보 제공 및 지원 서비스를 의미합니다.
② "이용자"라 함은 사이트에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 의미합니다.
 
제3조 (약관의 게시 및 개정)
① 회사는 이 약관의 내용을 사이트 초기화면에 게시하여 이용자가 약관의 내용을 쉽게 알 수 있도록 합니다.
② 회사는 필요한 경우 관련 법령을 위반하지 않는 범위 내에서 이 약관을 개정할 수 있습니다.
③ 회사가 약관을 개정할 경우에는 개정 내용과 시행일을 명시하여 시행일 7일 전부터 사이트에 공지합니다.
④ 이용자가 개정된 약관에 동의하지 않는 경우, 서비스 이용을 중단하고 회원탈퇴를 할 수 있습니다. 개정 약관 시행일 이후에도 서비스를 계속 이용할 경우 개정 약관에 동의한 것으로 간주합니다.
 
제4조 (서비스의 제공)
① 회사는 소액사건 지원을 위한 법률 정보 제공, 소장 작성 지원, 상담 등의 법률서비스를 제공합니다.
② 서비스 이용은 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 다만, 시스템 정기점검 또는 회사의 필요에 따라 서비스 이용이 일시 중지될 수 있으며, 이 경우 회사는 사전에 공지합니다.
③ 회사는 서비스 비용(착수금, 성공보수 등)과 소송 실비(인지대, 송달료)에 관하여 사이트에 최저비용을 예시로써 기재할 수 있고, 구체적인 사실관계에 관한 상담을 진행한 후 회원에게 그 비용을 고지한 후 계약을 체결합니다.
 
제5조 (회원가입)
① 회원가입은 이용자가 약관의 내용에 동의하고, 회사가 정한 절차에 따라 회원가입 신청을 한 후 회사가 이를 승낙함으로써 체결됩니다.
② 회사는 다음 각 호에 해당하는 경우 회원가입 신청을 승낙하지 않거나 사후에 회원 자격을 박탈할 수 있습니다.
1. 타인의 명의를 도용하여 신청한 경우
2. 허위 정보를 기재한 경우
3. 기타 회사의 서비스 운영을 방해한 경우
 
제6조 (회원의 의무)
① 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.
1. 타인의 정보 도용
2. 허위 정보 기재
3. 회사의 저작권, 지적재산권 침해
4. 서비스 방해 행위
② 회원은 개인정보 변경 시 지체 없이 회사에 알리거나 사이트를 통해 수정하여야 합니다.
 
제7조 (서비스 이용의 제한)
회사는 다음 각 호에 해당하는 경우 이용자의 서비스 이용을 제한할 수 있습니다.
1. 법령 위반 및 공서양속을 해치는 행위
2. 타인의 권리 침해 행위
3. 상담 신청 시 허위 내용을 등록하는 행위
4. 기타 서비스 운영을 방해하는 일체의 행위
 
제8조 (계약 해지 및 탈퇴)
① 회원은 언제든지 회사에 요청하여 회원 탈퇴를 할 수 있으며, 회사는 즉시 회원탈퇴를 처리합니다.
② 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원 자격을 제한, 정지 또는 상실시킬 수 있습니다.
1. 가입 신청 시 허위 내용을 기재한 경우
2. 기타 서비스 운영을 방해한 경우
 
제9조 (책임 제한)
① 회사는 천재지변 또는 불가항력으로 인해 서비스를 제공할 수 없는 경우 책임이 면제됩니다.
② 사이트 Q&A 등에서 제공되는 내용은 서비스의 이용에 도움을 주고자 제공되는 것으로서 법적 자문이나 해석, 법률상담을 위한 목적으로 제공된 것이 아닙니다. 또한, 이용자가 변호사의 법률적 자문을 구하지 않고 위 내용을 토대로 어떠한 행위를 하였을 경우 그 결과에 대하여 회사는 아무런 책임을 지지 않습니다.
③ 회사는 이용자가 서비스를 이용함에 있어 잘못된 정보와 자료를 회사에 제공함으로써 발생한 손해에 관하여 아무런 책임을 지지 않습니다.
 
제10조(저작권)
회사는 사이트에 게시된 내용물 일체에 관하여 저작권을 보유하며, 이용자는 사이트의 내용물을 무단으로 사용하여서는 아니 됩니다.
 
제11조 (분쟁의 해결)
① 이 약관의 해석과 적용에 관하여는 대한민국 법을 준거법으로 합니다.
② 서비스 이용과 관련하여 발생한 분쟁에 대한 소송은 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.`;

  const termsArray = TERMS_AND_CONDITIONS.split('\n \n');

  return (
    <Card
      title={<Title level={2}>이용약관 (법무법인 더 에이치 황해)</Title>}
      style={{ maxWidth: 800, margin: '0 auto' }}
    >
      {termsArray.map((term, index) => (
        <React.Fragment key={index}>
          <Paragraph>
            <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
              {term}
            </pre>
          </Paragraph>
          {index !== termsArray.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Card>
  );
}

export default TermsAndConditions;
