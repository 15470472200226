import { privateApi } from './index';

export const postModusignApi = async ({
  apartmentId,
  phoneNumber,
  name,
  ownerType,
  multipleOwners,
  buildingBlock,
  unit,
  userName,
}) =>
  await privateApi.post(`/modusign/document/${apartmentId}`, {
    phoneNumber,
    name,
    ownerType,
    multipleOwners,
    buildingBlock,
    unit,
    userName,
  });

// 문서 상세 정보 조회
export const getDocumentDetailApi = async (documentId) =>
  await privateApi.get(`/modusign/document/${documentId}`);
