import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userStateAtom, apartmentStateAtom } from '../store/atom';
import { useQueries } from 'react-query';
import { queryKey } from '../utils/querykey';
import { getApartmentInfo } from '../apis/apartment';
import { getUserInfo } from '../apis/account';
import Layout from '../components/Layout';
import { Flex, Typography, Button, message } from 'antd';

function PrivateRoute({ adminRoute }) {
  const { prefix } = useParams();
  console.log('prefix : ', prefix);
  const [userState, setUserState] = useRecoilState(userStateAtom);
  const [apartmentState, setApartmentState] =
    useRecoilState(apartmentStateAtom);
  // const [isLoading, setIsLoading] = useState(false);

  const accessToken = localStorage.getItem('accessTokenV2');

  const results = useQueries([
    {
      queryKey: 'getUserInfo',
      queryFn: () => getUserInfo(prefix),
      onSuccess: (res) => setUserState(res?.data),
      enabled: !!accessToken && !!prefix,
      onError: (error) => {
        console.log('error : ', error);
        message.error(error?.response?.data?.error);
        // 로그아웃 처리
        localStorage.removeItem('accessTokenV2');
        return <Navigate to={`/${prefix}/login`} />;
      },
      // 재시도 횟수 1회
      retry: 1,
    },
    {
      queryKey: 'getApartmentInfo',
      queryFn: () => getApartmentInfo(prefix),
      onSuccess: (res) => {
        console.log('하하하하하하 : ', res);
        setApartmentState(res?.data);
      },
      enabled: !!prefix,
    },
  ]);

  const isLoading = results.some((result) => result.isLoading);
  const isError = results.some((result) => result.isError);

  if (isLoading) return <div>Loading...</div>;
  // if (isError) return <div>Error occurred</div>;

  const isAdmin = userState.role === 'admin' && adminRoute;

  console.log({ userState });

  if (adminRoute && !isAdmin) {
    const currentPrefix = prefix || apartmentState.prefix;
    message.error(
      '관리자만 접근 가능합니다. 담당자에게 관리자 권한을 요청해주세요.'
    );
    return <Navigate to={`/${currentPrefix}/home`} />;
  }

  // 로그인 안되어 있으면 로그인 페이지로 이동
  if (!accessToken) {
    localStorage.setItem('redirectUrl', window.location.pathname);
    return <Navigate to={`/${prefix}/login`} />;
  }

  if (userState.status === 'pending') {
    return <Navigate to={`/${apartmentState.prefix}/signup`} />;
  }

  // 비활성화된 아파트는 접근 불가
  if (!apartmentState.isActive) {
    message.error('서비스 기간이 종료되었습니다.');
    return <Navigate to={`/${apartmentState.prefix}/login`} />;
  }

  return (
    <Layout isAdmin={isAdmin} prefix={prefix}>
      <Outlet />
    </Layout>
  );
}

export default PrivateRoute;
