import { useMediaQuery } from 'react-responsive';
import { Image } from 'antd';
import styles from './DefectsAlbum.module.css';

export default function ({ media }) {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  return (
    <div className={styles.album} data-ismobile={isMobile}>
      {media &&
        media.length > 0 &&
        media.map((medium, i) => {
          return (
            <Image
              key={i}
              className={styles.image}
              width={100}
              height={100}
              src={medium}
            />
          );
        })}
    </div>
  );
}
