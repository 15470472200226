import { useState } from 'react';
import { userStateAtom, apartmentStateAtom } from '../store/atom';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { useMutation } from 'react-query';
import { postModusignApi } from '../apis/document';
import alertIcon from '../assets/icon/alertIcon.png';

import { Flex, Typography, message, Popconfirm } from 'antd';
import { RiRobot2Line } from 'react-icons/ri';
import BotMessage from '../components/Chat/Message/Bot';
import UserMessage from '../components/Chat/Message/User';
import UserButton from '../components/Chat/Message/UserButton';
import ModusignButton from '../components/Chat/Message/ModusignButton';

import styles from '../styles/Document.module.css';
import chattingStyles from '../styles/Counsel.module.css';
import UserForm from '../components/Chat/Message/UserForm';
import ChatHeader from '../components/common/ChatHeader';
import assistantIcon from '../assets/icon/assistantIconV2.png';

import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import confirm from 'antd/es/modal/confirm';
import { resetConsentForm } from '../apis/account';

// 개인/법인, 복수소유자 여부 확인
const infoParagraphs = [
  `네 확인하였습니다. 다음으로 구분소유자 유형에 대해 몇 가지 확인하겠습니다.<br/>현재 세대의 구분 소유자가 개인인가요, 법인인가요?`,
  `네 확인하였습니다.\n다음으로, 현재 세대의 구분 소유자가 한 명인가요, 혹은 여러명인가요?`,
];

// 동의항목
const paragraphs = [
  `네 확인 하였습니다. 지금부터 본격적인 채권양도동의서 접수를 도와드리겠습니다. 먼저, 동의서의  세부 조항에 대한 설명을 드리겠습니다.\n\n
  \n\n**제1항**
  "갑"은 하자보수의무자(시행사, 시공사, 보증사 등 사업주체)에 대하여 가지고 있는 하자보수청구(하자보수종결합의 포함) 및 하자보수에 갈음하는 손해배상청구권(사용검사 전, 사용 검사 후 일체의 하자담보추급권 포함)을 "을"에게 양도하기로 하고 이에 본 양도증서에 서명날인한다.\n\n동의하시면 "<span class="highlight-blue">동의</span>"를 선택해주세요.`,

  '**제2항**\n"갑"은 위 하자보수의무자들을 상대로 한 위 손해배상채권의 양도통지 및 손해배상채권행사 등 ' +
    '하자소송에 관한 일체의 권한(단, 소제기전 합의종결에 관한 권한은 제외)을 "을"에게 위임한다.' +
    '\n\n동의하시면 "<span class="highlight-blue">동의</span>"를 선택해주세요.',

  '**제3항**\n승소판결금(합의금, 소송비용의 환수금 포함)의 사용방법은\n㉮ 계약에 의한 변호사 성공보수 및 소송대납비용은 승소금에서 공제하며,\n' +
    '㉯ "㉮"항의 비용을 제한 나머지 금액 중 전유부분 판결금은 각 세대에 지급하고, 공용부분 판결금은 입주자대표회의의 의결에 따라 하자보수를 위한 공사비용으로 사용함에 "갑"은 동의한다.' +
    '\n\n동의하시면 "<span class="highlight-blue">동의</span>"를 선택해주세요.',

  '**제4항**\n"갑"은 본 동의가 정상적인 의사와 권한으로 성립되었음을 확인하며 동의의사를 철회하지 않기로 하고, ' +
    '"을"이 하자보수청구 및 손해배상청구(하자소송)를 진행함에 있어 개인정보수집·이용·제공에 "갑"은 동의한다.' +
    '\n\n동의하시면 "<span class="highlight-blue">동의</span>"를 선택해주세요.',
];

export default function DocumentPage() {
  const [userState] = useRecoilState(userStateAtom);
  const [apartmentState] = useRecoilState(apartmentStateAtom);

  const { consentForm, idCard } = userState;
  const submitted = consentForm && idCard;

  // 개인인지, 법인인지
  const [ownerType, setOwnerType] = useState('INDIVIDUAL');
  // 복수소유자 여부
  const [multipleOwners, setMultipleOwners] = useState(false);

  const isOwnerInLocalStorage =
    localStorage.getItem('isOwner') === 'true' ? true : false;

  const [isOwner, setIsOwner] = useState(isOwnerInLocalStorage);
  // console.log('처음 : ', isOwner);
  const [messageList, setMessageList] = useState(
    JSON.parse(localStorage.getItem('agreements')) || [
      {
        type: 'bot',
        text: `안녕하세요, 하자보수에 갈음하는 손해배상채권에 대한 채권양도동의서 접수를 도와드리겠습니다.  본 절차를 시작하기 전 몇가지 확인 절차가 필요합니다.\n\n혹시 거주하고 계시는 **${userState.buildingBlock}동 ${userState.unit}호 세대**의 **소유주 본인**이신가요?`,
      },
      {
        type: 'user',
        text: 'confirm',
      },
    ]
  );

  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  // 모두싸인 호출
  const { mutate: postModusign, isLoading } = useMutation(postModusignApi, {
    onSuccess: (data) => {
      console.log({ data });
      message.success(
        '카카오 전자 서명 요청이 발송되었습니다.\n카카오톡을 확인해주세요!'
      );
    },
    onError: (error) => {
      console.error({ error });
      message.error(
        '카카오 전자 서명 요청 중 오류가 발생했습니다. 다시 시도해주세요.\n반복적으로 에러가 발생할 시 카카오톡 채널을 통해 문의해주세요.'
      );
    },
  });

  const getNewMessageList = (userMessage, botMessage, newUserMessage) => {
    setMessageList((prevMessages) => [
      ...prevMessages,
      userMessage,
      botMessage,
      newUserMessage,
    ]);

    localStorage.setItem(
      'agreements',
      JSON.stringify([...messageList, userMessage, botMessage, newUserMessage])
    );
  };

  // '소유주 본인이신가요?'
  const checkIsOwner = ({ value, text }) => {
    messageList.pop();

    const userMessage = { type: 'user', text: value };
    const botMessage = { type: 'bot' };
    const newUserMessage = { type: 'user' };

    if (value === '예') {
      setIsOwner(true);
      localStorage.setItem('isOwner', true);

      // 개인,법인 질문
      botMessage.text = infoParagraphs[0];
      newUserMessage.text = 'ownerType';

      // 동의항목
      // botMessage.text = paragraphs[0];
      // newUserMessage.text = 'agreement';
      // newUserMessage.index = 1;
    } else {
      setIsOwner(false);
      localStorage.setItem('isOwner', false);
      botMessage.text = '구분 소유주님과 어떤 관계이신가요?';
      newUserMessage.text = 'relation';
    }

    getNewMessageList(userMessage, botMessage, newUserMessage);
  };

  // '구분 소유자님과 어떤 관계이신가요?'
  const getRelation = (e, relation) => {
    e.preventDefault();
    messageList.pop();

    localStorage.setItem('relation', relation);

    const userMessage = { type: 'user', text: relation };
    const botMessage = {
      type: 'bot',
      text:
        '네 확인하였습니다.\n\n' +
        '채권양도동의서 전자서명 및 신분증 제출은 구분 소유자님의 휴대폰 단말기에서 진행부탁드립니다.\n\n' +
        '구분 소유자님의 성함과 연락처를 보내주세요.',
    };
    const newUserMessage = { type: 'user', text: 'info' };

    getNewMessageList(userMessage, botMessage, newUserMessage);
  };

  // '구분 소유자님의 성함과 연락처를 알려주세요'
  const getOwnerInfo = (e, name, phoneNumber) => {
    e.preventDefault();
    messageList.pop();

    localStorage.setItem('name', name);
    localStorage.setItem('phoneNumber', phoneNumber);

    const userMessage = {
      type: 'user',
      text: `소유주명 : ${name}\n전화번호 : ${phoneNumber}`,
    };

    // 동의항목
    // const botMessage = { type: 'bot', text: paragraphs[0] };
    // const newUserMessage = { type: 'user', text: 'agreement', index: 1 };

    // 개인,법인 질문
    const botMessage = { type: 'bot', text: infoParagraphs[0] };
    const newUserMessage = { type: 'user', text: 'ownerType' };

    getNewMessageList(userMessage, botMessage, newUserMessage);
  };

  // '개인인가요, 법인인가요?'
  const checkOwnerType = ({ value, text }) => {
    messageList.pop();

    setOwnerType(value);
    localStorage.setItem('ownerType', value);

    const userMessage = { type: 'user', text: text };
    const botMessage = { type: 'bot' };
    const newUserMessage = { type: 'user' };

    // 구분소유자가 법인인 경우, 복수 소유자는 존재할 수 없으므로, 바로 동의항목으로 이동
    if (value === 'CORPORATION') {
      botMessage.text = paragraphs[0];
      newUserMessage.text = 'agreement';
      newUserMessage.index = 1;
    }
    // 개인인 경우
    else {
      botMessage.text = infoParagraphs[1];
      newUserMessage.text = 'multipleOwners';
    }

    getNewMessageList(userMessage, botMessage, newUserMessage);
  };

  // 복수 소유자 여부 확인
  const checkMultipleOwners = ({ value, text }) => {
    messageList.pop();

    setMultipleOwners(value);
    localStorage.setItem('multipleOwners', value);

    const userMessage = { type: 'user', text: text };
    const botMessage = { type: 'bot' };
    const newUserMessage = { type: 'user' };

    // 동의항목 시작
    botMessage.text = paragraphs[0];
    newUserMessage.text = 'agreement';
    newUserMessage.index = 1;

    getNewMessageList(userMessage, botMessage, newUserMessage);
  };

  // 동의항목
  const checkAgreements = ({ value, index }) => {
    messageList.pop();

    const userMessage = { type: 'user', text: value };
    const botMessage = { type: 'bot' };
    const newUserMessage = { type: 'user' };

    const showNextParagraph = () => {
      botMessage.text = paragraphs[index];
      newUserMessage.index = index + 1;
      newUserMessage.text = 'agreement';
    };

    if (value === '동의') {
      if (index === 1) {
        showNextParagraph();
      } else if (index === 2) {
        showNextParagraph();
      } else if (index === 3) {
        showNextParagraph();
      } else {
        botMessage.text = 'done';
      }
    } else {
      botMessage.text = `${index}항에 동의하셔야 양도증서 서명이 가능합니다.`;
      newUserMessage.index = index;
      newUserMessage.text = 'agreement';
    }

    getNewMessageList(userMessage, botMessage, newUserMessage);
  };

  // console.log('isOwnerInLocalStorage :: ', isOwnerInLocalStorage);
  console.log({ userState });

  const handlePostModusign = () => {
    postModusign({
      apartmentId: apartmentState.id,
      phoneNumber: isOwner
        ? userState.phoneNumber
        : localStorage.getItem('phoneNumber'),
      name: isOwner ? userState.name : localStorage.getItem('name'),
      ownerType: localStorage.getItem('ownerType') || 'INDIVIDUAL',
      multipleOwners: localStorage.getItem('multipleOwners') || false,
      buildingBlock: userState.buildingBlock,
      unit: userState.unit,
      userName: userState.name,
      relation: localStorage.getItem('relation'),
    });
  };

  // 유저 메시지 렌더링 로직
  const userMessageRender = (message) => {
    // 개인인지 법인인지 (개인, 법인)
    if (message.text === 'ownerType') {
      return <UserButton type='ownerType' onClick={checkOwnerType} />;
    }
    // 복수소유자 여부 (한 명, 여러명)
    else if (message.text === 'multipleOwners') {
      return <UserButton type='multipleOwners' onClick={checkMultipleOwners} />;
    }
    // '소유주 본인이신가요?' (예, 아니오)
    else if (message.text === 'confirm') {
      return <UserButton type='confirm' onClick={checkIsOwner} />;
    }
    // 동의항목 (동의, 동의하지않음)
    else if (message.text === 'agreement') {
      return (
        <UserButton
          agreement={true}
          index={message.index}
          onClick={checkAgreements}
          type='agreement'
        />
      );
    }
    // 사용자가 구분소유자가 아닌 경우, 구분소유자와의 관계 확인 질문
    else if (message.text === 'relation') {
      return <UserForm onSubmit={getRelation} />;
    }
    // 구분소유자 정보 (이름, 전화번호)
    else if (message.text === 'info') {
      return <UserForm onSubmit={getOwnerInfo} multi={true} />;
    }

    //  ?
    else {
      if (message.text) {
        return <UserMessage message={message.text} />;
      }
    }
  };

  console.log({ ownerType, multipleOwners });

  const confirmRefresh = () => {
    confirm({
      title: <ConfirmTitle>동의 내역 삭제</ConfirmTitle>,
      icon: (
        <img
          src={alertIcon}
          alt='alert'
          style={{
            width: '24px',
            marginRight: '10px',
            marginLeft: '2px',
            position: 'absolute',
          }}
        />
      ),
      content: (
        <ConfirmContent>
          동의한 내역이 삭제되며, 양도 동의서 조항 안내 절차를 다시 시작합니다.
          <br />
          대화를 새로고침 하시겠습니까?
        </ConfirmContent>
      ),
      onOk() {
        onRefresh();
      },
      onCancel() {
        console.log('Cancel');
      },
      okCancel: true,
      closable: false,
      className: 'confirm-refresh',
      okText: '새로고침하기',
      cancelText: '아니오',
      okButtonProps: {
        style: {
          fontSize: '15px',
          width: '100%',
          height: '40px',
          borderRadius: '8px',
          backgroundColor: 'var(--main-color)',
          color: '#fff',
          border: '1px solid var(--main-color)',
          marginRight: '9px',
        },
      },
      cancelButtonProps: {
        style: {
          fontSize: '15px',
          width: '100%',
          height: '40px',
          borderRadius: '8px',
          backgroundColor: '#fff',
          color: 'var(--main-color)',
          border: '1px solid var(--main-color)',
        },
      },
    });
  };

  const onRefresh = async () => {
    // await resetConsentForm();

    localStorage.removeItem('agreements');
    localStorage.removeItem('name');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('isOwner');
    localStorage.removeItem('ownerType');
    localStorage.removeItem('multipleOwners');
    setIsOwner(false);
    setOwnerType('INDIVIDUAL');
    setMultipleOwners(false);
    setMessageList([
      {
        type: 'bot',
        text:
          `안녕하세요, 하자보수에 갈음하는 손해배상채권에 대한 채권양도동의서 접수를 도와드리겠습니다.  본 절차를 시작하기 전 몇가지 확인 절차가 필요합니다. ` +
          `\n\n혹시 거주하고 계시는 **${userState.buildingBlock}동 ${userState.unit}호 세대**의 **소유주 본인**이신가요?`,
      },
      {
        type: 'user',
        text: 'confirm',
      },
    ]);
  };
  const isSubmitted = userState.consentForm && userState.idCard;

  return (
    <Container justify='flex-start' vertical gap={12}>
      <ChatHeader
        title={'채권 양도 동의서 및 신분증 제출'}
        hideRefresh={isSubmitted && true}
        isDocumentPage={true}
        onRefresh={confirmRefresh}
      />
      <Window data-ismobile={isMobile}>
        {messageList.length > 0 &&
          messageList.map((message, i) => (
            <div key={i}>
              {message.type === 'bot' ? (
                <BotArea>
                  <NameTag>
                    <AssistantIcon src={assistantIcon} alt='더에이치' />
                  </NameTag>
                  {message.text === 'done' ? (
                    <ModusignButton
                      onClick={handlePostModusign}
                      submitted={submitted}
                      isLoading={isLoading}
                    />
                  ) : (
                    <BotMessage
                      message={message.text}
                      isDocumentPage={true}
                      dataIsBot={false}
                    />
                  )}
                </BotArea>
              ) : (
                <>{userMessageRender(message)}</>
              )}
            </div>
          ))}
      </Window>
    </Container>
  );
}

const Container = styled(Flex)`
  overflow-y: hidden;
  background-color: #fff;
  /* height: calc(var(--vh, 1vh) * 100); */
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1a1d34;
  .ant-modal-confirm-content {
    color: red;
  }
`;
const Footer = styled(Flex)`
  margin: 0;
  box-sizing: border-box;
  padding: 16px 20px;
  height: 80px;
  background-color: #abbecd;
  width: 100%;
  z-index: 9;
  /* position: fixed;
  bottom: 0; */
  @media screen and (min-width: 768px) {
    width: calc(100% - 240px);
  }
`;
const Window = styled(Flex)`
  /* background-color: red; */
  box-sizing: border-box;
  margin-top: 42px;

  display: flex;
  flex-direction: column;
  gap: 18px;
  flex-grow: 1;

  overflow: auto;
  padding: 32px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0 16px 40px;
  }
`;

const ConfirmTitle = styled.h3`
  position: relative;
  color: #131313;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-left: 36px;
  /* padding-left: 27.5px; */
`;
const ConfirmContent = styled.p`
  color: #434343;
  font-family: 'Noto Sans KR';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  margin-left: 9px;
  margin-top: 20px;
  word-break: keep-all;
`;

const BotArea = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;
const NameTag = styled.div`
  margin-bottom: 16px;
`;
const AssistantIcon = styled.img`
  width: 32px;
  z-index: 0;
`;
