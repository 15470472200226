import { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getApartmentInfo } from '../apis/apartment';
import { getUserInfo, signup } from '../apis/account';
import { Flex, Typography, Button, message } from 'antd';
import styles from '../styles/Login.module.css';
import ChatHeader from '../components/common/ChatHeader';
import styled from 'styled-components';
import uncheckedBox from '../assets/icon/uncheckedBox.png';
import checkedBox from '../assets/icon/checkedBox.png';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isRegisteredAtom, userStateAtom } from '../store/atom';
import { useMediaQuery } from 'react-responsive';

export default function SignupV2() {
  const { prefix } = useParams();
  // console.log({ prefix });
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState('');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [buildingBlockValue, setBuildingBlockValue] = useState('');
  const [unitValue, setUnitValue] = useState('');

  const name = useRef();
  const phoneNumber = useRef();
  const buildingBlock = useRef();
  const unit = useRef();
  const infoAgreement = useRef();
  const marketingAgreement = useRef();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [userId, setUserId] = useState('');
  const [apartmentId, setApartmentId] = useState('');

  useEffect(() => {
    (async () => {
      const user = await getUserInfo(prefix);
      const apartment = await getApartmentInfo(prefix);

      if (user.data.id) {
        setUserId(user.data.id);
      }
      if (apartment.data.id) {
        setApartmentId(apartment.data.id);
      } else {
        message.error(
          '아파트 정보를 불러오는 중 오류가 발생했습니다. 다시 시도해주세요.'
        );
        navigate(`/loginCode`);
      }
    })();
  }, []);

  // console.log({ prefix });
  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      !userId ||
      !buildingBlock.current.value ||
      !unit.current.value ||
      !name.current.value ||
      !phoneNumber.current.value
    ) {
      message.error('값을 입력해주세요.');
      return;
    }
    if (!isChecked.includes('info')) {
      message.error('개인정보 수집 및 이용 동의를 체크해주세요.');
      return;
    } else if (!isChecked.includes('marketing')) {
      message.error('마케팅 정보 활용 동의를 체크해주세요.');
      return;
    }

    try {
      const payload = {
        id: userId,
        buildingBlock: buildingBlock.current.value,
        unit: unit.current.value,
        apartmentId: apartmentId,
        name: name.current.value,
        phoneNumber: phoneNumber.current.value,
        isTenant: isChecked.includes('isTenant'),
      };

      const response = await signup(payload);
      if (response.status === 200 || response.status === 201) {
        message.success('회원가입이 완료되었습니다.');
        navigate(`/${prefix}/home`);
      } else {
        message.error('회원가입 중 오류가 발생했습니다. 다시 시도해주세요.');
      }
    } catch (error) {
      console.log(error?.response?.data.error);
      message.error(error?.response?.data.error);
    }
  };
  const inputStyle = (value) => (value ? { border: '1px solid #000' } : {});

  // custom checkbox
  const [isChecked, setIsChecked] = useState([]);

  const handleCheckboxChange = (agreement) => {
    if (isChecked.includes(agreement)) {
      setIsChecked(isChecked.filter((item) => item !== agreement));
      return;
    } else {
      setIsChecked([...isChecked, agreement]);
    }
  };

  // console.log({ isChecked });

  return (
    <form onSubmit={onSubmit}>
      <Container
        vertical
        align='center'
        justify='space-between'
        className={styles.container}
        isMobile={isMobile}
      >
        <Title>계정 정보 입력</Title>

        <Flex
          vertical
          align='center'
          gap={30}
          style={{
            width: '100%',
            marginTop: '50px',
          }}
        >
          <Flex
            vertical
            align='flex-start'
            justify='center'
            gap={12}
            style={{
              width: '100%',
            }}
          >
            <Agreement>
              <input
                ref={infoAgreement}
                type='checkbox'
                style={{
                  width: '20px',
                }}
                checked={isChecked.includes('isTenant')}
                onChange={() => handleCheckboxChange('isTenant')}
                className={styles.checkboxInput}
              />
              <span className={'checkboxImage'}></span>
              <Typography className={'agreements'}>
                임차인이신 경우, 체크해주세요.
              </Typography>
            </Agreement>
            <Flex vertical gap={4} className={styles.inputRow}>
              <Typography className={styles.labelV2}>대표 입주민 명</Typography>
              <input
                ref={name}
                required='대표 입주민 명을 입력해주세요'
                type='text'
                className={styles.input}
                onChange={(e) => setNameValue(e.target.value)}
                style={inputStyle(nameValue)}
              />
            </Flex>
            <Flex vertical gap={4} className={styles.inputRow}>
              <Typography className={styles.labelV2}>휴대폰 번호</Typography>
              <input
                ref={phoneNumber}
                required='휴대폰 번호를 입력해주세요'
                type='text'
                className={styles.input}
                onChange={(e) => setPhoneNumberValue(e.target.value)}
                style={inputStyle(phoneNumberValue)}
                placeholder='예 : 01012345678'
                // 숫자가 아니거나 11자리 초과일 경우 alert
                onInput={(e) => {
                  if (isNaN(e.target.value) || e.target.value.length > 11) {
                    alert('올바른 전화번호를 입력해주세요(예 : 01012341234)');
                    e.target.value = '';
                    return;
                  }
                }}
              />
            </Flex>
            <Flex vertical gap={4} className={styles.inputRow}>
              <Typography className={styles.labelV2}>동</Typography>
              <input
                ref={buildingBlock}
                required='동을 입력해주세요'
                type='text'
                className={styles.input}
                placeholder='숫자만 입력'
                onChange={(e) => {
                  // 숫자가 아닐 경우 alert
                  if (isNaN(e.target.value)) {
                    alert('숫자만 입력해주세요');
                    e.target.value = '';
                    return;
                  }
                  setBuildingBlockValue(e.target.value);
                }}
                style={inputStyle(buildingBlockValue)}
              />
            </Flex>
            <Flex vertical gap={4} className={styles.inputRow}>
              <Typography className={styles.labelV2}>호수</Typography>
              <input
                ref={unit}
                required='호수를 입력해주세요'
                type='text'
                className={styles.input}
                placeholder='숫자만 입력'
                onChange={(e) => {
                  // 숫자가 아닐 경우 alert
                  if (isNaN(e.target.value)) {
                    alert('숫자만 입력해주세요');
                    e.target.value = '';
                    return;
                  }
                  setUnitValue(e.target.value);
                }}
                style={inputStyle(unitValue)}
              />
            </Flex>
          </Flex>
          <Flex gap={8} vertical>
            <Agreement>
              <input
                ref={infoAgreement}
                type='checkbox'
                style={{
                  width: '20px',
                }}
                checked={isChecked.includes('info')}
                onChange={() => handleCheckboxChange('info')}
                className={styles.checkboxInput}
              />
              <span className={'checkboxImage'}></span>
              <Typography className={'agreements'}>
                개인정보 수집 및 이용 동의
              </Typography>
            </Agreement>
            <Agreement>
              <input
                ref={marketingAgreement}
                type='checkbox'
                style={{
                  width: '20px',
                }}
                checked={isChecked.includes('marketing')}
                onChange={() => handleCheckboxChange('marketing')}
                className={styles.checkboxInput}
              />
              <span className={'checkboxImage'}></span>
              <Typography className={'agreements'}>
                마케팅 정보 활용 동의
              </Typography>
            </Agreement>
          </Flex>
        </Flex>

        <SubmitBtn type='text' htmlType='submit' style={{ marginTop: '36px' }}>
          <span>완료</span>
        </SubmitBtn>
      </Container>
    </form>
  );
}
const Container = styled(Flex)`
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: scroll;
  padding: 0 20px;
  background-color: #00285b;
`;
const Title = styled.h1`
  font-size: 17px;
  font-weight: 600;
  padding: 10px;
  height: 42px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  @media screen and (min-width: 768px) {
    width: calc(100% - 240px);
  }
`;
const Agreement = styled.label`
  display: flex;
  align-items: center;
  input[type='checkbox'] {
    display: none;
  }
  &:focus,
  &:active {
    outline: none;
    background: none;
  }
  .checkboxImage {
    width: 20px;
    height: 20px;
    background-image: url(${uncheckedBox}); /* 기본 이미지 경로 */
    background-size: cover;
    margin-right: 8px;
    cursor: pointer;
  }
  input[type='checkbox']:checked + .checkboxImage {
    background-image: url(${checkedBox}); /* 체크된 이미지 경로 */
  }
  .agreements {
    font-size: 20px;
    color: #fff;
  }
  .label {
    font-weight: 500;
    font-size: 20px;
    color: #fff;
  }
`;
const SubmitBtn = styled(Button)`
  background-color: var(--main-color);
  width: 100%;
  color: white;
  height: 48px;
  font-size: 16px;
  margin-bottom: 45px;
  padding: 15px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    text-align: center;
    font-feature-settings:
      'liga' off,
      'clig' off;

    /* Body/16 Bold */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
`;
const InputRow = styled(Flex)`
  width: 100%;
`;
