import { privateApi } from '.';

export const startChatting = async (type) =>
  await privateApi.post('/chat/start', { type: type });

export const sendMessage = async (threadId, query) => {
  return await privateApi.post(`/chat/${threadId}`, {
    query: query,
  });
};

export const speech = async (threadId, formData) => {
  return await privateApi.post(`speech/${threadId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
