import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import arrowIcon from '../../assets/icon/icon-park-outline_left.png';
import { apartmentStateAtom } from '../../store/atom';
import { useNavigate, useParams } from 'react-router-dom';
import { assignLawyer, getIncidentDetailApi } from '../../apis/incident';
import { incidentStatusNameEnum } from '../../utils/terminology';
import { ConfigProvider, Table, Dropdown, Space, message, Modal } from 'antd';
import ReactMarkdown from 'react-markdown';
import lawyerIcon from '../../assets/icon/lawyerIcon.png';
import chatIcon from '../../assets/icon/chatIcon.png';
import downArrowIcon from '../../assets/icon/downArrowIcon.png';
import { getLawyerAccountsApi } from '../../apis/account';
import ChatDownloadComponent from '../../components/ChatDownload';

export default function AdminIncident() {
  const { incidentId } = useParams();
  const [incidentData, setIncidentData] = useState({});
  const [lawyerAccounts, setLawyerAccounts] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLawyer, setSelectedLawyer] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [haveDefaultLawyer, setHaveDefaultLawyer] = useState(null);

  const getIncidentDetail = async () => {
    const data = await getIncidentDetailApi(incidentId);
    setIncidentData(data);
    console.log('incident data : ', data);
    if (data.lawyerId) {
      setHaveDefaultLawyer(data.lawyerId);
      setSelectedLawyer(
        lawyerAccounts.find((lawyer) => lawyer.id == data.lawyerId)
      );
    }
  };
  const getLawyerAccounts = async () => {
    const data = await getLawyerAccountsApi();
    setLawyerAccounts(data);
    console.log('lawyer accounts : ', data);
  };

  const tableData = [
    {
      id: incidentData.id,
      type: incidentData.type,
      status: incidentData.status,
    },
  ];

  const nowrapText = (text) => (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'scroll',
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {text}
    </div>
  );
  const columns = [
    {
      title: nowrapText('연번'),
      dataIndex: 'number',
      key: 'number',
      width: '18.75%',
      render: (_, data) => (
        <StyledData
          style={{
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {data.id}번
        </StyledData>
      ),
    },
    {
      title: nowrapText('유형'),
      dataIndex: 'type',
      key: 'type',
      // width: '80px',
      width: '25%',
      render: (_, data) => (
        <StyledData
          style={{
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {data.type}
        </StyledData>
      ),
    },
    {
      title: nowrapText('진행 현황'),
      dataIndex: 'idCard',
      key: 'idCard',
      width: ' 56.25%',
      render: (_, data) => (
        <StyledData
          style={{
            display: 'flex',
            justifyContent: 'center',
            color:
              data.status === 'pending'
                ? 'red'
                : data.status === 'inProgress'
                  ? 'green'
                  : 'blue',
          }}
        >
          {data.status === 'pending'
            ? '시작 전'
            : data.status === 'inProgress'
              ? '진행 중'
              : '진행 완료'}
        </StyledData>
      ),
    },
  ];

  const navigate = useNavigate();

  const onClickDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (dropdownOpen) {
      setSelectedLawyer(null);
    }
  };
  const onClickLawyer = (id) => {
    setSelectedLawyer(lawyerAccounts.find((lawyer) => lawyer.id === id));
  };

  // 변호사 지정 '완료하기'
  const selectConfirm = async () => {
    const res = await assignLawyer({
      incidentId: incidentData.id,
      lawyerId: selectedLawyer.id,
    });
    if (res.status === 201 || res.status === 200) {
      message.success('변호사 지정이 완료되었습니다.');
      getIncidentDetail();
      // window.location.reload();
    } else {
      message.error('변호사 지정에 실패했습니다. 관리자에게 문의해주세요.');
    }
  };
  const confirmAssignLawyer = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    selectConfirm();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getIncidentDetail();
    getLawyerAccounts();
  }, [dropdownOpen]);

  return (
    <>
      <Container>
        <Header>
          <div className='backBtn' onClick={() => navigate(-1)}>
            <img src={arrowIcon} alt='arrow' />
            <p>목록으로</p>
          </div>
          <Title>상세 보기</Title>
        </Header>
        <Content>
          <Box>
            <BoxTitle>법률 상담</BoxTitle>
            <BoxContent>
              <ConfigProvider
                theme={{
                  token: {
                    padding: 12,
                  },
                }}
              >
                <CustomTable
                  columns={columns}
                  rowKey='id'
                  dataSource={tableData}
                  bordered
                  pagination={false}
                />
              </ConfigProvider>
            </BoxContent>
          </Box>
          <Box>
            <BoxTitle>의뢰인 정보</BoxTitle>
            <ClientInfo>
              <div className='row'>
                <strong>이름 : </strong>
                <p>{incidentData.client?.name}</p>
              </div>
              <div className='row'>
                <strong>연락처 : </strong>
                <p>{incidentData.client?.phoneNumber}</p>
              </div>
            </ClientInfo>
          </Box>
          <Box>
            <BoxTitle>사실 관계 요약본</BoxTitle>
            <FactSummary>
              <ReactMarkdown className={`markdown-reset`}>
                {incidentData.summary}
              </ReactMarkdown>
            </FactSummary>
          </Box>
        </Content>
        <Footer>
          <FooterBtn
            onClick={onClickDropdown}
            dropdownOpen={dropdownOpen}
            className='lawyerSelection'
          >
            <div className='group'>
              <img src={lawyerIcon} alt='lawyer' />
              {incidentData?.lawyer?.name ? (
                <p>{incidentData.lawyer?.name} 변호사</p>
              ) : (
                <p>변호사 지정하기</p>
              )}
            </div>
            <img
              src={downArrowIcon}
              alt='downArrow'
              className='downArrowIcon'
            />
          </FooterBtn>

          {dropdownOpen && lawyerAccounts && (
            <LawyerSelection className={dropdownOpen ? '' : 'closed'}>
              {lawyerAccounts.map((lawyer) => (
                <Lawyer
                  key={lawyer.id}
                  onClick={() => {
                    onClickLawyer(lawyer.id);
                  }}
                  selected={selectedLawyer?.id === lawyer.id}
                >
                  <p>{lawyer.name} 변호사</p>
                </Lawyer>
              ))}
            </LawyerSelection>
          )}
          {/* <FooterBtn>
            <img src={chatIcon} alt='chatDownload' />
            <p>채팅 기록 다운 받기</p>
          </FooterBtn> */}
          <ChatDownloadComponent
            chatHistory={incidentData?.consultationHistory}
          />
        </Footer>
      </Container>
      {selectedLawyer && haveDefaultLawyer !== selectedLawyer.id && (
        <SelectConfirmBtn onClick={confirmAssignLawyer}>
          변호사 지정하기
        </SelectConfirmBtn>
      )}
      <Modal
        title='변호사 지정을 완료하시겠어요?'
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        style={{ padding: '20px', fontSize: '16px', textAlign: 'center' }}
      >
        <BtnGroup>
          <ModalButton onClick={handleCancel}>취소하기</ModalButton>
          <ModalButton onClick={handleOk} primary>
            완료하기
          </ModalButton>
        </BtnGroup>
      </Modal>
    </>
  );
}

const Container = styled.div`
  padding: 0 20px;
  min-height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
`;
const Header = styled.div`
  padding: 11px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;
  left: 0;
  .backBtn {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    /* 세로 가운데 정렬 */
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    cursor: pointer;
    img {
      width: 24px;
    }
    p {
      margin-left: 2px;
    }
  }
`;
const Title = styled.h1`
  font-size: 17px;
  font-weight: 600;
`;
const Content = styled.div`
  margin-top: calc(23px + 17px + 22px);
`;

const Box = styled.div``;
const BoxTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
`;
const BoxContent = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
`;

const StyledData = styled.div`
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  display: flex;
`;
const CustomTable = styled(Table)``;
const ClientInfo = styled(BoxContent)`
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 0;
  strong {
    font-weight: 500;
    margin-right: 4px;
  }
  .row {
    display: flex;
    align-items: flex-start;
    margin: 0 10px;
    &:first-child {
      margin-bottom: 12px;
    }
  }
`;
const FactSummary = styled(BoxContent)`
  box-shadow: 0px 0px 20px rgba(139, 160, 177, 0.2);
  border-radius: 12px;
`;

const Footer = styled.div`
  padding-bottom: 28px;
  margin-bottom: 45px;
`;
const FooterBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  border: 2px solid #f0f0f0;
  padding: 12px 25px 12px 65px;
  margin-bottom: 16px;
  border-radius: 12px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 24px;
    margin-right: 12px;
  }
  .group {
    display: flex;
    align-items: center;
  }
  .downArrowIcon {
    margin-left: 36px;
  }

  /* transition: border-color 0.5s; */
  &.lawyerSelection {
    border-color: ${(props) => (props.dropdownOpen ? '#004F8A' : '#f0f0f0')};
  }
`;
const LawyerSelection = styled.div`
  padding: 20px 15px;
  border: 2px solid #004f8a;
  border-radius: 14px;
  margin-bottom: 12px;
`;
const Lawyer = styled.div`
  padding: 15px 22px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#1D6DA8' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border-radius: 12px;
`;
const SelectConfirmBtn = styled.button`
  font-size: 24px;
  font-weight: 600;
  padding: 11px 8px;
  background-color: #1d6da8;
  color: #fff;
  width: 100vw;
  position: fixed;
  bottom: 0;
`;
const CustomModal = styled.div``;
const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
`;
const ModalButton = styled.button`
  width: 100%;
  padding: 13px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 25px;
  &:first-child {
    border-radius: 0 0 0 10px;
  }
  &:last-child {
    border-radius: 0 0 10px 0;
  }
  ${(props) =>
    props.primary
      ? `
    background-color: #1D6DA8;
    color: white;
  `
      : `
    background-color: #f0f0f0;
    color: #9091A0;
  `}
`;
