import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export default function ({ data }) {
  const basicDataset = {
    labels: ['1단지', '2단지', '3단지', '4단지', '5단지'],
    datasets: [
      {
        label: '타일깨짐',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#4472C5',
      },
      {
        label: '목재',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#EC7C34',
      },
      {
        label: '균열',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#990000',
      },
      {
        label: '기기장치',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#A4A7AB',
      },
      {
        label: '누수',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#FCC404',
      },
      {
        label: '단열',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#5B9BD5',
      },
      {
        label: '총계',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#74AC44',
      },
    ],
  };

  const [processedData, setProcessedData] = useState(basicDataset);

  const addData = (model, i, data) => {
    model.datasets[0].data[i] += Number(data?.tileCount);
    model.datasets[1].data[i] += Number(data?.woodCount);
    model.datasets[2].data[i] += Number(data?.crackCount);
    model.datasets[3].data[i] += Number(data?.applianceCount);
    model.datasets[4].data[i] += Number(data?.condensationCount);
    model.datasets[5].data[i] += Number(data?.insulationCount);
    model.datasets[6].data[i] += Number(data?.totalDefects);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const model = {
        labels: ['1단지', '2단지', '3단지', '4단지', '5단지'],
        datasets: [
          {
            label: '타일깨짐',
            data: [0, 0, 0, 0, 0],
            backgroundColor: '#4472C5',
          },
          {
            label: '목재',
            data: [0, 0, 0, 0, 0],
            backgroundColor: '#EC7C34',
          },
          {
            label: '균열',
            data: [0, 0, 0, 0, 0],
            backgroundColor: '#990000',
          },
          {
            label: '기기장치',
            data: [0, 0, 0, 0, 0],
            backgroundColor: '#A4A7AB',
          },
          {
            label: '누수',
            data: [0, 0, 0, 0, 0],
            backgroundColor: '#FCC404',
          },
          {
            label: '단열',
            data: [0, 0, 0, 0, 0],
            backgroundColor: '#5B9BD5',
          },
          {
            label: '총계',
            data: [0, 0, 0, 0, 0],
            backgroundColor: '#74AC44',
          },
        ],
      };

      data.map((datum) => {
        if (datum.buildingBlock.startsWith('1')) {
          addData(model, 0, datum);
        } else if (datum.buildingBlock.startsWith('2')) {
          addData(model, 1, datum);
        } else if (datum.buildingBlock.startsWith('3')) {
          addData(model, 2, datum);
        } else if (datum.buildingBlock.startsWith('4')) {
          addData(model, 3, datum);
        } else if (datum.buildingBlock.startsWith('5')) {
          addData(model, 4, datum);
        }
      });

      setProcessedData(model);
    }
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        beginAtZero: true,
        max: 1200, // y축의 최대값을 1200으로 설정
        ticks: {
          stepSize: 200, // y축 눈금의 간격을 200으로 설정
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        text: '단지별 하자 접수 현황',
        color: '#1C5A91',
        font: {
          size: 16,
          weight: 600,
        },
      },
    },
  };

  console.log('processedData : ', processedData);

  return <>{processedData && <Bar data={processedData} options={options} />}</>;
}
