import { useRef } from 'react';
import { Flex, Typography } from 'antd';
import styles from './Message.module.css';

export default function UserForm({ onSubmit, multi = false }) {
  const relation = useRef();
  const name = useRef();
  const phoneNumber = useRef();

  const _onSubmit = (e) => {
    if (multi) {
      onSubmit(e, name.current.value, phoneNumber.current.value);
    } else {
      onSubmit(e, relation.current.value);
    }
  };

  return (
    <div className={styles.position}>
      <div className={styles.messageBox} style={{ padding: 16 }}>
        <form className={styles.form} onSubmit={_onSubmit}>
          {multi ? (
            <Flex vertical gap={8}>
              <input
                type='text'
                className={styles.input}
                placeholder='소유주 명'
                ref={name}
                required
              />
              <input
                type='text'
                className={styles.input}
                placeholder='전화번호'
                ref={phoneNumber}
                required
              />
            </Flex>
          ) : (
            <input
              type='text'
              className={styles.input}
              placeholder='소유주와의 관계'
              ref={relation}
              required
            />
          )}
          <button type='submit' className={styles.submitButton}>
            <Typography
              style={{ fontSize: 12, fontWeight: 600, color: '#1C5A91' }}
            >
              입력
            </Typography>
          </button>
        </form>
      </div>
    </div>
  );
}
