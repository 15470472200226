import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { apartmentStateAtom, userStateAtom } from '../store/atom';
import styled from 'styled-components';
import textIcon from '../assets/icon/textIconV2.png';
import voiceIcon from '../assets/icon/voiceIconV2.png';
import homeBtnIcon from '../assets/icon/home-button.png';
import documentSubmitBtn from '../assets/icon/document-submit.png';
import submitCompleteBtn from '../assets/icon/submit-complete.png';

export default function HomeV2() {
  const navigate = useNavigate();
  const [apartmentState] = useRecoilState(apartmentStateAtom);
  const [userState] = useRecoilState(userStateAtom);
  console.log(userState);
  const isAdmin = userState.role === 'admin';

  const startDefectInvestigation = () => {
    if (apartmentState.prefix === 'songpa-heliocity') {
      window.open('http://pf.kakao.com/_hPxdBG/chat', '_blank');
    } else if (apartmentState.prefix === 'songdo-centralcity') {
      window.open('http://pf.kakao.com/_TxhneG/chat', '_blank');
    } else {
      alert('등록되지 않은 아파트 입니다.');
    }
  };

  return (
    <Container>
      <AlignCenter>
        <ChattingLinkButton
        // onClick={() => navigate(`/${apartmentState.prefix}/counsel/text`)}
        >
          <Icon src={textIcon} alt='문자형 AI 상담' />
          <LinkText>
            <strong>문자형</strong>
            <br />
            AI 상담
          </LinkText>
        </ChattingLinkButton>
        <ChattingLinkButton
        // onClick={() => navigate(`/${apartmentState.prefix}/counsel/voice`)}
        >
          <Icon src={voiceIcon} alt='대화형 AI 법률 상담' />
          <LinkText>
            <strong>대화형</strong>
            <br />
            AI 상담
          </LinkText>
        </ChattingLinkButton>
      </AlignCenter>
      <DocumentContainer>
        <DocumentButton

        // disabled={userState.consentForm && userState.idCard}
        >
          {userState.consentForm && userState.idCard ? (
            <img
              onClick={() => navigate(`/${apartmentState.prefix}/document`)}
              src={submitCompleteBtn}
              alt='submit complete'
            />
          ) : (
            <img
              onClick={() => navigate(`/${apartmentState.prefix}/document`)}
              src={documentSubmitBtn}
              alt='document submit'
            />
          )}
        </DocumentButton>
      </DocumentContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  font-family: 'Pretendard', sans-serif;
  background: #00285b;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
`;

const AlignCenter = styled.div`
  margin: 0 20px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  cursor: pointer;
`;

const ChattingLinkButton = styled(ButtonBase)`
  height: 200px;
  flex-direction: column;
  background: #00285b;
  border-radius: 12px;
  box-shadow: 0px 0px 20px 0px rgba(139, 160, 177, 0.3);
`;

const Icon = styled.img`
  width: 67px;
`;

const LinkText = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #bdbdbd;
  margin-top: 12px;
  text-align: center;

  strong {
    font-weight: 700;
  }
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 40px; */
  margin-top: 64px;
`;

const DocumentButton = styled.div`
  width: 76.7vw;
  /* height: 276.55px; */
  flex-shrink: 0;
  background: transparent;

  img {
    width: 100%;
    height: 100%;
  }
`;
