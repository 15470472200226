import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Tooltip } from 'antd';

export default function AdminPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location.pathname.split('/').pop()
  );

  const handleTabChange = (value) => {
    setSelectedTab(value);
    navigate(`${value}`);
  };

  return (
    <Container justify='flex-start' vertical>
      {/* <RadioGroup>
        <RadioItem
          key={'document'}
          isselected={selectedTab === 'document' ? 'true' : ''}
          onClick={() => handleTabChange('document')}
        >
          <input
            type='radio'
            name='radio'
            value={'document'}
            checked={selectedTab === 'document'}
            onChange={() => {}}
            style={{ display: 'none' }}
          />
          <p>채권양도</p>
        </RadioItem>
        <RadioItem
          key={'defects'}
          isselected={selectedTab === 'defects' ? 'true' : ''}
          onClick={() => handleTabChange('defects')}
        >
          <input
            type='radio'
            name='radio'
            value={'defects'}
            checked={selectedTab === 'defects'}
            onChange={() => {}}
            style={{ display: 'none' }}
          />
          <p>하자조사</p>
        </RadioItem>
        <RadioItem
          key={'incidents'}
          isselected={selectedTab === 'incidents' ? 'true' : ''}
          onClick={() => handleTabChange('incidents')}
        >
          <input
            type='radio'
            name='radio'
            value={'incidents'}
            checked={selectedTab === 'incidents'}
            onChange={() => {}}
            style={{ display: 'none' }}
          />
          <p>법률상담</p>
        </RadioItem>
      </RadioGroup> */}
      <Outlet />
    </Container>
  );
}

const Container = styled(Flex)`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 20px;
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 4px 20px 0px rgba(139, 160, 177, 0.2);
  padding: 4px;
  margin-top: 22px;
`;

const RadioItem = styled.label`
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  color: ${(props) => (props.isselected ? '#fff' : '#5d7999')};
  background-color: ${(props) =>
    props.isselected ? 'var(--main-color)' : 'transparent'};
  width: 100%;
  min-height: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  p {
    text-align: center;
  }
`;
