import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { apartmentStateAtom, userStateAtom } from '../../store/atom';
import { useMediaQuery } from 'react-responsive';
import {
  getAllMembers,
  getMemberList,
  getMembersBySearch,
  getSubmissionStatus,
} from '../../apis/account';
import {
  Table,
  Tag,
  Input,
  Button,
  Space,
  Flex,
  Switch,
  ConfigProvider,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styles from '../../styles/Members.module.css';
import styled from 'styled-components';
import downloadIcon from '../../assets/icon/downloadIcon.png';
import { getDocumentDetailApi } from '../../apis/document';

export default function AdminDocument() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [apartmentState] = useRecoilState(apartmentStateAtom);
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  console.log({ apartmentState });

  const [userState] = useRecoilState(userStateAtom);
  const isAdmin = userState.role === 'admin';
  const [totalCount, setTotalCount] = useState(0);

  const [searchBuildingBlock, setSearchBuildingBlock] = useState('');
  const [searchUnit, setSearchUnit] = useState('');

  const [submissionStatus, setSubmissionStatus] = useState();

  const [onlyShowNotSubmitted, setOnlyShowNotSubmitted] = useState(false);

  const fetchMemberList = async () => {
    const { data } = await getMemberList(
      currentPage,
      8,
      apartmentState.id,
      onlyShowNotSubmitted,
      searchBuildingBlock,
      searchUnit
    );
    console.log({ fetchMembersData: data });
    setData(data || []);
    setFilteredData(data.users || []);
    setTotalCount(data.totalCount);

    const submissionData = await getSubmissionStatus({
      apartmentId: apartmentState?.id,
    });
    console.log({ submissionData });
    setSubmissionStatus(submissionData);
  };

  useEffect(() => {
    fetchMemberList();
  }, [currentPage, apartmentState.id, isAdmin, onlyShowNotSubmitted]);

  const onPageChange = (page) => {
    setPage(page);
  };

  const handleSearch = async () => {
    const lowercasedSearchBuildingBlock = searchBuildingBlock.toLowerCase();
    const lowercasedSearchUnit = searchUnit.toLowerCase();
    const { users, totalCount: count } = await getMembersBySearch({
      buildingBlock: lowercasedSearchBuildingBlock,
      unit: lowercasedSearchUnit,
      onlyShowNotSubmitted: onlyShowNotSubmitted,
      apartmentId: apartmentState.id,
    });

    setTotalCount(count);
    setFilteredData(users);
    setPage(1);
  };

  const handleReset = () => {
    setSearchBuildingBlock('');
    setSearchUnit('');
    setFilteredData(data.users);
    setTotalCount(data.totalCount);
  };

  const nowrapText = (text) => (
    <div
      style={{
        whiteSpace: 'nowrap',
        // overflow: 'scroll',
        color: '#fff',
        fontWeight: 600,
        // 가운데 정렬
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        // fontSize: 12,
        // !isMobile일때 가운데 정렬
        justifyContent: !isMobile ? 'center' : 'flex-start',
        fontSize: !isMobile ? 14 : 12,
      }}
    >
      {text}
    </div>
  );
  const nowrapTextCentered = (text) => (
    <div
      style={{
        whiteSpace: 'nowrap',
        // overflow: 'scroll',
        fontSize: '12px',
        color: '#fff',
        // backgroundColor: 'red',
        // 가운데 정렬
        display: 'flex',
        fontWeight: 600,
        justifyContent: 'center',
        alignItems: 'center',
        // fontSize: 14,
        fontSize: !isMobile ? 14 : 12,
      }}
    >
      {text}
    </div>
  );

  // pdf파일 다운로드
  const onClickDownload = async (documentId) => {
    const res = await getDocumentDetailApi(documentId);
  };

  const getColumns = () => {
    const baseColumns = [
      {
        title: nowrapText('동'),
        dataIndex: 'buildingBlock',
        key: 'buildingBlock',
        width: isMobile ? '25%' : '15%',
        render: (item) => <StyledData isMobile={isMobile}>{item}동</StyledData>,
      },
      {
        title: nowrapText('호수'),
        dataIndex: 'unit',
        key: 'unit',
        width: isMobile ? '25%' : '15%',
        render: (item) => <StyledData isMobile={isMobile}>{item}호</StyledData>,
      },
      {
        title: nowrapTextCentered('신분증 제출 여부'),
        dataIndex: 'idCard',
        key: 'idCard',
        width: isMobile ? '25%' : '15%',
        render: (_, data) => (
          <StyledData isMobile={isMobile} style={{ justifyContent: 'center' }}>
            <StyledTag
              color={data.idCard ? 'green' : 'red'}
              bordered={false}
              style={{
                backgroundColor: 'transparent',
                fontSize: 12,
              }}
            >
              {data.idCard ? '제출' : '미제출'}
            </StyledTag>
          </StyledData>
        ),
      },
      {
        title: nowrapTextCentered('동의서 제출 여부'),
        dataIndex: 'consentForm',
        key: 'consentForm',
        width: isMobile ? '25%' : '15%',
        render: (_, data) => (
          <StyledData isMobile={isMobile} style={{ justifyContent: 'center' }}>
            <StyledTag
              color={data.consentForm ? 'green' : 'red'}
              bordered={false}
              style={{
                backgroundColor: 'transparent',
                fontSize: 12,
              }}
            >
              {data.consentForm ? <p>제출</p> : <p>미제출</p>}
            </StyledTag>
          </StyledData>
        ),
      },
    ];

    if (!isMobile) {
      baseColumns.unshift({
        title: nowrapText('성함'),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        render: (item) => <StyledData>{item}</StyledData>,
      });

      baseColumns.push({
        title: nowrapText('제출일자'),
        dataIndex: 'signedAt',
        key: 'signedAt',
        width: '25%',
        render: (item) => (
          <StyledData>
            {item ? new Date(item).toLocaleDateString('ko-KR') : '-'}
          </StyledData>
        ),
      });
    }

    return baseColumns;
  };

  // 화면 높이 계산 (scroll)
  const [tableHeight, setTableHeight] = useState(0);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const updateTableHeight = () => {
      if (tableContainerRef.current) {
        const windowHeight = window.innerHeight;
        const containerTop =
          tableContainerRef.current.getBoundingClientRect().top;
        const newHeight = windowHeight - containerTop - 110; // 25px is the bottom margin
        setTableHeight(newHeight);
      }
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => window.removeEventListener('resize', updateTableHeight);
  }, []);

  console.log({ tableHeight });

  return (
    <Container isMobile={isMobile}>
      <SubmissionStatus>
        {isMobile && (
          <>
            <h2>
              <strong>{apartmentState.name}</strong>
            </h2>
            <h2>채권양도 동의서 및 신분증 제출 현황</h2>
          </>
        )}
        <div className='boxWrapper'>
          <StatusBox>
            <h3>접수 세대 수</h3>
            <div className='numbers'>
              <strong>{submissionStatus?.submissionCount}</strong>
              <p>/{submissionStatus?.totalAccounts}</p>
            </div>
          </StatusBox>
          <StatusBox>
            <h3>총 접수율</h3>
            <div className='numbers'>
              <strong>{submissionStatus?.submissionRate}</strong>
              <p>%</p>
            </div>
          </StatusBox>
        </div>
      </SubmissionStatus>
      <TableWrapper isMobile={isMobile}>
        <HeaderBar>
          {!isMobile && (
            <h2>
              <strong>{apartmentState.name}</strong>
            </h2>
          )}
          <div className='wrapper'>
            <Space
              style={{
                marginRight: 12,
              }}
            >
              <StyledInput
                placeholder='동'
                value={searchBuildingBlock}
                onChange={(e) => setSearchBuildingBlock(e.target.value)}
                onPressEnter={handleSearch}
              />
              <StyledInput
                placeholder='호수'
                value={searchUnit}
                onChange={(e) => setSearchUnit(e.target.value)}
                onPressEnter={handleSearch}
              />
              <StyledButton
                type='primary'
                onClick={handleSearch}
                icon={<SearchOutlined />}
              >
                검색
              </StyledButton>
              {/* <Button onClick={handleReset}>초기화</Button> */}
            </Space>
            <Space
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p
                style={{
                  fontSize: 10,
                  width: 75,
                  marginBottom: -5,
                }}
              >
                미제출 세대만 보기
              </p>
              <ConfigProvider
                theme={{
                  components: {
                    Switch: {
                      handleSize: 20,
                      trackHeight: 14,
                      trackMinWidth: 34,
                      trackPadding: -3,
                      handleShadow: '0 4px 4px 0 rgba(0, 35, 11, 0.2)',
                    },
                  },
                }}
              >
                <CustomSwitch
                  onChange={() => {
                    setOnlyShowNotSubmitted(!onlyShowNotSubmitted);
                  }}
                  style={{
                    margin: 0,
                  }}
                />
              </ConfigProvider>
            </Space>
          </div>
        </HeaderBar>

        <div ref={tableContainerRef} style={{ flex: 1 }}>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  // footerColor: 'red',
                  cellPaddingBlock: '12px',
                  cellPaddingInline: '10px',
                },
              },
            }}
          >
            <CustomTable
              columns={getColumns()}
              rowKey='id'
              dataSource={filteredData}
              pagination={{
                pageSize: 8,
                total: totalCount,
                current: currentPage,
                onChange: onPageChange,
                position: ['bottomCenter'],
              }}
              className={styles.window}
              bordered
              scroll={{ y: tableHeight }}
            />
          </ConfigProvider>
        </div>
      </TableWrapper>
    </Container>
  );
}

const Container = styled.div`
  /* margin-top: 22px; */
  width: 100%;
  /* height: calc(100vh - 22px - 25px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) =>
    !props.isMobile &&
    `
    background-color: #f1f5f9;
    padding: 32px 48px;
    `}
`;

const StyledData = styled.div`
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  display: flex;
  /* justify-content: center; */
  /* overflow: scroll; */
  /* max-width: 2.5rem; */

  ${(props) =>
    !props.isMobile &&
    `
      justify-content: center;
    `}
`;
const StyledTag = styled(Tag)``;

const SubmissionStatus = styled.div`
  margin-bottom: 37px;
  color: #3f4759;
  h2 {
    font-size: 18px;
    margin-bottom: 12px;
    /* &:first-child {
      margin-bottom: 6px;
    } */
    strong {
      font-family: 'Pretendard Variable';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 19.051px; /* 105.838% */
    }
  }
  .boxWrapper {
    display: flex;
    width: 100%;
  }
`;
const StatusBox = styled.div`
  box-shadow:
    0px 0px 11.431px 0px rgba(255, 255, 255, 0.4) inset,
    0px 4.763px 14.288px 0px rgba(0, 79, 138, 0.12);
  backdrop-filter: blur(61.91524887084961px);

  width: 270px;
  margin-right: 24px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 10px;
  background: #fff;

  h3 {
    color: #3f4759;
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .numbers {
    display: flex;
    align-items: center;
    height: 56px;
    strong {
      color: #004f8a;
      text-align: center;
      font-family: Pretendard;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      font-size: 20px;
      margin-top: 10px;
      color: #3f4759;
      font-family: Pretendard;
      font-size: 35.556px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const HeaderBar = styled(Flex)`
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wrapper {
    display: flex;
    align-items: center;
  }
  h2 strong {
    color: #3f4759;
    font-family: 'Pretendard Variable';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.051px; /* 105.838% */
  }
`;
const CustomSwitch = styled(Switch)`
  /* background-color: transparent; */
`;
const CustomTable = styled(Table)`
  overflow-y: scroll;
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .ant-pagination-prev {
    border: 1px solid #f1f1f1;
    margin-right: 4px;
  }
  .ant-pagination-next {
    border: 1px solid #f1f1f1;
    margin: 0;
  }
  .ant-pagination-item {
    border-color: #f1f1f1;
    margin-right: 4px;
    &.ant-pagination-item-active {
      border-color: #004f8a;
      background-color: #004f8a;
      a {
        color: #fff;
      }
    }
  }
  /* 테이블 내부 구분선 제거 */
  .ant-table-tbody > tr > td {
    border: none !important;
  }
  /* 헤더 구분선 제거 */
  .ant-table-thead > tr > th {
    border: none !important;
  }

  /* overflow-y: scroll; */
`;

const StyledInput = styled(Input)`
  display: flex;
  width: 70px;
  height: 33px;
  padding: 7.62px 14.288px;
  justify-content: center;
  align-items: center;
  gap: 9.525px;
  border-radius: 3.81px;
  border: 1px solid #eeeef0;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), rgba(44, 131, 250, 0.9);
`;
const StyledButton = styled(Button)`
  display: flex;
  height: 33.339px;
  padding: 7.62px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 3.81px;
  background: linear-gradient(0deg, #004f8a 0%, #004f8a 100%),
    rgba(44, 131, 250, 0.9);
  color: white;
  border: none;
  /* transition: all 0.3s ease; */

  &:active {
    background: linear-gradient(0deg, #002a4a 0%, #002a4a 100%),
      rgba(44, 131, 250, 0.9);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const TableWrapper = styled.div`
  ${(props) =>
    !props.isMobile &&
    `  background-color: #fff;
  padding: 19px;
  border-radius: 9.525px;
  border: 0.953px solid
    var(--bg-background-border-400300, rgba(255, 255, 255, 0.6));
  background: var(
    --BG-Background-300,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 0.01%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.7)
  );

  /* Shadow */
  box-shadow:
    0px 0px 11.431px 0px rgba(255, 255, 255, 0.4) inset,
    0px 4.763px 14.288px 0px rgba(0, 79, 138, 0.12);
  backdrop-filter: blur(61.91524887084961px);`}
`;
