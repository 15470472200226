import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledForm = styled(Form)`
  width: 400px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: #1890ff;
  border-color: #1890ff;
  &:hover,
  &:focus {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
`;

function PaymentLogin() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const PAYMENT_ACCOUNT = {
    ID: 'payment-test',
    PW: 'payment!@#test',
  };

  const onFinish = (values) => {
    if (
      values.username === PAYMENT_ACCOUNT.ID &&
      values.password === PAYMENT_ACCOUNT.PW
    ) {
      message.success('로그인 성공!');
      navigate('/chat');
      localStorage.setItem('isPaymentLogin', true);
      // 여기에 로그인 성공 후 처리 로직 추가
    } else {
      message.error('아이디 또는 비밀번호가 일치하지 않습니다.');
    }
  };

  return (
    <Container>
      <StyledForm form={form} onFinish={onFinish}>
        <Form.Item
          name='username'
          rules={[{ required: true, message: '아이디를 입력해주세요!' }]}
        >
          <Input placeholder='아이디' />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]}
        >
          <Input.Password placeholder='비밀번호' />
        </Form.Item>
        <Form.Item>
          <StyledButton type='primary' htmlType='submit'>
            로그인
          </StyledButton>
        </Form.Item>
      </StyledForm>
    </Container>
  );
}

export default PaymentLogin;
