import { Flex, Typography } from 'antd';
import styles from './StatisticsItem.module.css';

export default function ({ label, value, unit }) {
  return (
    <Flex
      vertical
      align='center'
      justify='center'
      className={styles.item}
      gap={8}
    >
      <Typography.Text className={styles.label}>{label}</Typography.Text>
      <Flex align='flex-end' justify='center' gap={4}>
        <Typography.Text className={styles.value}>{value}</Typography.Text>
        <Typography.Text className={styles.unit}>{unit}</Typography.Text>
      </Flex>
    </Flex>
  );
}
