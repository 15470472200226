import React, { useState } from 'react';
import { Button, Card, Carousel, message, Typography } from 'antd';
import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PortOne from '@portone/browser-sdk/v2';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

function PaymentToggle() {
  const [showProducts, setShowProducts] = useState(false);
  const navigate = useNavigate();

  const productList = [
    {
      id: 1,
      name: '패키지 1',
      content: '지급 명령 신청 + 강제 집행 신청',
      description: `이 패키지는 채무자에 대한 법적 조치를 위한 종합 솔루션입니다.
        먼저, 지급 명령 신청을 통해 채무자에게 빚을 갚도록 법원의 명령을 받습니다. 
        만약 채무자가 이에 응하지 않을 경우, 강제 집행 신청을 통해 채무자의 재산을 압류하거나 경매에 부치는 등의 강제적인 조치를 취할 수 있습니다. 
        이 패키지는 채권 회수를 위한 효과적이고 체계적인 법적 절차를 제공합니다.`,
      price: 190000,
    },
    {
      id: 2,
      name: '패키지 2',
      content: '소액 소송 + 강제 집행 신청',
      description: `이 패키지는 채무자에 대한 법적 조치를 위한 종합 솔루션입니다.
        먼저, 소액 소송을 통해 채무자에게 빚을 갚도록 법원의 명령을 받습니다. 
        만약 채무자가 이에 응하지 않을 경우, 강제 집행 신청을 통해 채무자의 재산을 압류하거나 경매에 부치는 등의 강제적인 조치를 취할 수 있습니다. 
        이 패키지는 채권 회수를 위한 효과적이고 체계적인 법적 절차를 제공합니다.`,
      price: 350000,
    },
  ];

  const carouselRef = React.useRef();

  // 결제 관련
  const TEST_API_KEY =
    'kMc7x1FDQ7r6umqlcTBSBLcXyiol90YIP2DzTxETz400aN6iowpXUrnJkHCIk1IKhQ3SgOaz5kid8BM7';
  const STORE_ID = 'store-c0cd2b2d-8f6e-4586-89d3-b545b2a73a8f';
  const CHANNEL_KEY = 'channel-key-bc71c77c-a7d5-4337-8d25-7e0e71c0ec94';
  const CLIENT_URL = 'http://localhost:3000';
  // 현재 페이지의 url
  const currentUrl = window.location.href;

  const getPayment = async (product) => {
    const paymentId = `payment-${crypto.randomUUID()}`;
    const response = await PortOne.requestPayment({
      // Store ID 설정
      storeId: STORE_ID,
      // 채널 키 설정
      channelKey: CHANNEL_KEY,
      paymentId,
      orderName: product.name,
      totalAmount: product.price,
      currency: 'CURRENCY_KRW',
      payMethod: 'EASY_PAY',
      // bypass: {
      //   kakaopay: {
      //     custom_message: '여기가 안내 문구영역입니다.',
      //   },
      // },
      redirectUrl: `${currentUrl}`,
    });
    if (response.code != null) {
      // 오류 발생
      return alert(response.message);
    }
    console.log({ response });
    // alert(response);
    navigate('/success');
  };

  return (
    <Container>
      <Button
        style={{
          width: '200px',
        }}
        type='primary'
        onClick={() => setShowProducts(!showProducts)}
      >
        법률 상품 조회
      </Button>
      {showProducts && (
        <CarouselWrapper>
          <ArrowButton
            onClick={() => carouselRef.current.prev()}
            direction='left'
          >
            <LeftOutlined />
          </ArrowButton>
          <StyledCarousel ref={carouselRef}>
            {productList.map((product) => (
              <Card key={product.id}>
                <Title level={3}>{product.name}</Title>
                <Paragraph strong>{product.content}</Paragraph>
                <Paragraph>{product.description}</Paragraph>
                <Paragraph strong>
                  가격: {product.price.toLocaleString()}원
                </Paragraph>
                <Button type='primary' onClick={() => getPayment(product)}>
                  결제하기
                </Button>
              </Card>
            ))}
          </StyledCarousel>
          <ArrowButton
            onClick={() => carouselRef.current.next()}
            direction='right'
          >
            <RightOutlined />
          </ArrowButton>
        </CarouselWrapper>
      )}
    </Container>
  );
}

export default PaymentToggle;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 80%;
  max-width: 600px;
  margin-top: 20px;
`;

const StyledCarousel = styled(Carousel)`
  .ant-card {
    margin: 0 10px;
    height: 100%;
  }

  .slick-dots li button {
    background: #1890ff;
  }

  .slick-dots li.slick-active button {
    background: #1890ff;
  }
`;

const ArrowButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.direction === 'left' ? 'left: -40px;' : 'right: -40px;')}
  z-index: 1;
`;
