import { RecoilRoot } from 'recoil';
import Router from './routes/router';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import reset from 'styled-reset';
import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
const GlobalStyle = createGlobalStyle`
${reset}
html,body {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;

  padding: 0;
  margin: 0;

  overscroll-behavior: none;

  background-color: #fff;
  box-sizing: border-box;
  /* overflow-y: hidden; */
}

/* confirm 모달 */
.ant-modal-content {
  padding: 0 !important;
  border-radius: 10px !important;
  padding: 16px 18px !important;
  margin: 0 18px !important;
}
.ant-modal-confirm-btns {
  margin-top: 20px !important;
  width: 100% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-typography {
    font-family: 'Pretendard', sans-serif !important;
  }
.markdown-reset * {
  all: revert;
}
.markdown-reset {
  font-family: 'Pretendard', sans-serif;
  line-height: normal;
  color: initial;
  /* padding: 3px; */
  margin: 0;
  line-height: inherit;
  font-family: inherit;
  box-sizing: border-box;
  /* white-space: pre-wrap; */
  p {
    margin: 0;
    word-break: break-all;
    white-space: pre-wrap;
  }
}
.markdown-reset.pre-wrap {
  white-space: pre-wrap !important;
}

/* antd 테이블 */
thead .ant-table-cell {
  background-color: rgba(40, 48, 65, 0.90) !important;
}


* {
  font-family: inherit;
  box-sizing: border-box;
}
body {
  /* position: fixed; */
  /* 스크롤 방지 */
  /* background-color: #dde5ea; */
  /* overflow: hidden; */
}

button {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
}

input {
  outline: 0;
  border: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --main-color: #004f8a;
  --vh: 100%;
}
`;

function App() {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
  });

  return (
    <RecoilRoot>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#004F8A',
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router />
        </ThemeProvider>
      </ConfigProvider>
    </RecoilRoot>
  );
}

export default App;
