import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isRegisteredAtom } from '../../store/atom';
import { message } from 'antd';

function KakaoCallback() {
  // const prefix = localStorage.getItem('prefix');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // axios.defaults.withCredentials = true;
  const authCode = searchParams.get('code');
  const prefix = searchParams.get('state');

  const [isRegistered, setIsRegistered] = useRecoilState(isRegisteredAtom);

  const getAccessToken = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/account/kakao`,
        {
          code: authCode,
          prefix: prefix,
        }
      );

      if (response.status === 404) {
        message.error(response.data);
        return navigate('/');
      }

      response = response.data;

      if (response?.token) {
        localStorage.setItem('accessTokenV2', response?.token);
      }
      if (response?.is_registered) {
        setIsRegistered(true);
        // 회원가입이 되어있으면 홈으로 이동
        const redirectUrl = localStorage.getItem('redirectUrl');
        if (redirectUrl) {
          localStorage.removeItem('redirectUrl');
          return navigate(redirectUrl);
        } else {
          return navigate(`/${prefix}/home`);
        }
      }
      // 회원가입 안되어 있으면 signup페이지로 이동
      else {
        setIsRegistered(false);
        return navigate(`/${prefix}/signup`);
      }
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        // 서버에서 보낸 에러 메시지 표시
        message.error(e.response.data.error);
      } else {
        // 기본 에러 메시지 표시
        message.error('로그인 중 오류가 발생했습니다.');
      }
      navigate(`/${prefix}/login`);
    } finally {
      setLoading(false);
    }

    return;
  };

  useEffect(() => {
    (async () => {
      await getAccessToken();
    })();
  });

  return <div>Kakao Login Redirecting...</div>;
}

export default KakaoCallback;
