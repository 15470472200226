import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import UserMessage from '../../components/Chat/Message/User';
import BotMessage from '../../components/Chat/Message/Bot';
import Loader from '../../components/Chat/Message/Loader';
import { Flex, Button, Typography } from 'antd';
import styles from '../../styles/Counsel.module.css';
import { apartmentStateAtom, userStateAtom } from '../../store/atom';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import assistantIcon from '../../assets/icon/assistantIcon.png';
import sendBtnGrey from '../../assets/icon/sendBtnGrey.png';
import { streamChatMessages } from '../../apis/counsel';
import ChatHeader from '../../components/common/ChatHeader';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

export default function CounselText() {
  const [currentMessage, setCurrentMessage] = useState('');
  const [messageList, setMessageList] = useState(
    JSON.parse(localStorage.getItem('messageList')) || []
  );
  const [isTyping, setIsTyping] = useState(false);
  const [userState] = useRecoilState(userStateAtom);
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  const [apartmentState] = useRecoilState(apartmentStateAtom);

  const onChange = (e) => {
    setCurrentMessage(e.target.value);
  };

  const onMessage = (message) => {
    setMessageList((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastBotMessageIndex = updatedMessages
        .map((msg) => msg.role)
        .lastIndexOf('assistant');

      if (
        lastBotMessageIndex === -1 ||
        lastBotMessageIndex !== updatedMessages.length - 1
      ) {
        updatedMessages.push({ role: 'assistant', content: message });
      } else {
        updatedMessages[lastBotMessageIndex].content += message;
      }

      localStorage.setItem('messageList', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
  };
  const onFinish = () => {
    // botMessage를 빈 값으로 하나 추가
    setMessageList((prevMessages) => {
      const updatedMessages = [
        ...prevMessages,
        { role: 'assistant', content: '' },
      ];
      localStorage.setItem('messageList', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isTyping) return;

    const query = currentMessage;
    if (!query.trim()) return;

    const userMessage = { role: 'user', content: currentMessage };
    setMessageList((prevMessages) => {
      const updatedMessages = [...prevMessages, userMessage];
      localStorage.setItem('messageList', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
    setCurrentMessage('');

    setIsTyping(true);
    // AI 응답 스트리밍
    await streamChatMessages(
      messageList,
      query,
      onMessage,
      onFinish,
      apartmentState.id
    );
    setIsTyping(false);
  };

  const onRefresh = async () => {
    setMessageList([]);
    localStorage.removeItem('messageList');
  };

  const onKeyDown = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      await onSubmit(e);
    }
  };

  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!isTyping && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isTyping]);
  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  // 키보드 올라올때 화면 밀림 방지
  const headerRef = useRef(null);
  const divRef = useRef(null);
  const originalHeaderTop = '0px';
  let prevVisualViewport = useRef(0).current;
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const handleVisualViewPortResize = () => {
      const currentVisualViewport = Number(window.visualViewport?.height);
      console.log('currentVisualViewport ', currentVisualViewport);
      if (divRef.current) {
        divRef.current.style.height = `${currentVisualViewport}px`;
        document.body.style.height = `${currentVisualViewport}px`;
        document.body.style.backgroundColor = '#dde5ea';
        // divRef.current.style.height = `0`;
        window.scrollTo(0, 0);
      }
      // Initial call to set the height
      // setTimeout(handleVisualViewPortResize, 0);

      if (window.visualViewport) {
        window.visualViewport.onresize = handleVisualViewPortResize;
      }
    };

    handleVisualViewPortResize();

    return () => {
      if (window.visualViewport) {
        window.visualViewport.onresize = null; // Clean up the event listener
      }
    };
  }, [divRef]);

  return (
    <Container ref={divRef}>
      {/* <div className='header' ref={headerRef}> */}
      <ChatHeader title={'문자형 AI 법률 상담'} onRefresh={onRefresh} />
      {/* </div> */}
      <Window vertical justify='flex-start' data-ismobile={isMobile}>
        <div className={styles.botArea}>
          <div className={styles.nameTag}>
            <img
              src={assistantIcon}
              alt='더에이치'
              className={styles.assistantIcon}
            />
          </div>
          <BotMessage
            message={`안녕하세요, 더에이치입니다.\n어떤 상담을 원하시나요?`}
          />
        </div>
        {messageList.length > 0 &&
          messageList.map((message, i) => (
            <div key={i}>
              {message.content.length > 0 &&
                (message.role === 'assistant' ? (
                  <div className={styles.botArea}>
                    <div className={styles.nameTag}>
                      <img
                        src={assistantIcon}
                        alt='더에이치'
                        className={styles.assistantIcon}
                      />
                    </div>
                    <BotMessage message={message.content} />
                  </div>
                ) : (
                  <UserMessage message={message.content} />
                ))}
            </div>
          ))}
        <div
          ref={messagesEndRef}
          style={{
            margin: 0,
            padding: 0,
            // width: 100,
            // height: 100,
            // backgroundColor: 'red',
          }}
        />
        {/* {isTyping && <Loader />} */}
      </Window>

      <Footer align='center' justify='center'>
        <form className={styles.inputArea} onSubmit={onSubmit}>
          <textarea
            className={styles.input}
            onChange={onChange}
            value={currentMessage}
            placeholder='메시지를 입력하세요.'
            onKeyDown={onKeyDown}
            disabled={isTyping}
            ref={inputRef}
          />
          <button type='submit' className={styles.sendBtn}>
            <img src={sendBtnGrey} alt='전송' />
          </button>
        </form>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  overflow-y: hidden;
  background-color: #dde5ea;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  /* margin-top: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* transition: 0.1s; */
`;
const Footer = styled(Flex)`
  margin: 0;
  box-sizing: border-box;
  padding: 16px 20px;
  height: 80px;
  background-color: #abbecd;
  width: 100%;
  z-index: 9;
  /* position: fixed;
  bottom: 0; */
  @media screen and (min-width: 768px) {
    width: calc(100% - 240px);
  }
`;
const Window = styled(Flex)`
  /* background-color: red; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;

  overflow: auto;
  padding: 32px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;
