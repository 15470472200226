import { atom } from 'recoil';

export const userStateAtom = atom({
  key: 'userState',
  default: {
    id: 0,
    kakaoId: '',
    name: '',
    apartmentId: 0,
    buildingBlock: '',
    unit: '',
    status: '',
    idCard: false,
    consentForm: false,
    role: '',
  },
});

export const isRegisteredAtom = atom({
  key: 'isRegistered',
  default: false,
});

export const apartmentStateAtom = atom({
  key: 'apartmentState',
  default: {
    id: 0,
    name: '',
    address: '',
    total: 0,
    prefix: '',
    templateId: '',
    tileBlockId: '',
    woodBlockId: '',
    crackBlockId: '',
    applianceBlockId: '',
    condensationBlockId: '',
    insulationBlockId: '',
    etcBlockId: '',
    completeBlockId: '',
    createdAt: '',
    deletedAt: '',
    updatedAt: '',
  },
});
