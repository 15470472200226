import React from 'react';
import { Typography, Card, Divider } from 'antd';

const { Title, Paragraph } = Typography;

function PrivacyPolicy() {
  const PRIVACY_POLICY_CONTENT = `개인정보 처리방침
 
법무법인 더 에이치 황해(이하 '회사')는 개인정보 보호법 제30조에 따라 다음과 같이 본 사이트에 접속하여 가입한 이용자(이하 '회원')에 대한 개인정보 처리지침을 수립, 공개합니다.
 
개인정보의 처리 목적
회사는 다음 목적을 위하여 회원의 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
- 회원 가입 및 관리 : 본인 인증, 회원 가입 및 관리, 서비스 부정이용 방지 등
- 서비스 제공 : 사실관계 정리, 서비스 진행상황 알림, 각종 문서 및 발송
- 금융거래 관련 업무 : 요금제에 따른 요금 결제 및 환불, 현금영수증 발급 등 법률사무 수행을 위한 금융거래 관련 업무
- 마케팅 및 광고 활용 : 가입한 회원에 대한 이벤트 및 광고 등 활용
 
수집하는 개인정보의 항목
회사는 서비스 이용에 필요한 최소한의 정보를 수집하여 처리하며, 수집한 개인정보를 목적 외의 용도로 이용하지 않습니다. 수집하는 개인정보의 항목은 다음과 같습니다.
- 필수항목 : 성명, 생년월일, 주민등록번호, 휴대전화번호, 통신사, 성별, 주소, 이메일주소, 계좌번호, 결제정보
- 서비스제공 과정에서 수집되는 정보 : 채팅 메시지 수신정보, 현금영수증 번호, 자동생성정보 (IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 외부 플랫폼을 통한 인구통계학적 특성정보, 웹사이트 방문 경로)
 
개인정보의 처리 및 보유 기간
회사는 특별한 사정이 없는 한 수집하는 개인정보 항목에 기재된 보유기간 동안 개인정보를 보유합니다. 단, 관계 법령의 준수를 위하여 의무적으로 보관하여야 하는 경우 정해진 보유기간에 따라 필요 최소한의 범위 내에서 보관하며 보관하는 정보는 아래와 같습니다.
[근거법령] 전자상거래 등에서의 소비자 보호에 관한 법률
- 표시/광고에 관한 기록 6개월
- 계약 또는 청약 철회 등에 관한 기록 5년
- 대금 결제 및 재화 등의 공급에 관한 기록 5년
- 소비자의 불만 및 분쟁 처리에 관한 기록 3년
[근거법령] 통신비밀보호법
- 서비스 이용에 관한 로그 기록 3개월
 
개인정보의 제3자 제공에 관한 사항
회사는 수집한 개인정보의 항목에 표시된 목적으로만 개인정보를 이용하며, 원칙적으로 회원의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
- 회원이 사전에 동의한 경우
- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사 기관의 요구가 있는 경우
- 회사의 서비스 이행을 위하여 개인정보를 제3자에게 제공하고 있는 경우

 
개인정보처리의 위탁에 관한 사항
개인정보의 업무 위탁은 회원에게 원활한 서비스를 제공하기 위하여 분야별 전문성을 갖춘 수탁 업체에 일부 업무를 위탁하는 것을 의미하며, 회사의 책임 하에 위탁한 범위 내에서만 개인정보가 처리됩니다.
 
회원의 권리와 행사방법
회원은 언제든지 개인정보보호를 위한 권리를 행사할 수 있습니다. 회원이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 해당 개인정보를 이용하거나 제공하지 않습니다.
[정보 주체의 권리]
- 개인정보 열람 요구
- 개인정보 정정, 삭제 요구
- 개인정보 처리 정지 요구
- 개인정보 이용 및 제공 조회
[정보 주체의 권리 행사 방법]
본 사이트의 관리자에게 이메일을 보내 권리를 행사할 수 있으며, 본인 확인 후 이에 대해 지체 없이 조치하겠습니다.
위 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수 있으며, 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
 
개인정보의 파기에 관한 사항
회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 경우 지체없이(5영업일 이내) 해당 개인정보를 파기합니다. 단, 관계 법령의 준수를 위하여 의무적으로 보관하여야 하는 경우는 법령이 정한 기간 동안 최소한의 정보만을 안전하게 보관합니다.
- 파기절차 : 회사는 개인정보의 파기 사유가 발생한 경우 개인정보 보호 책임자의 관리 하에 안전한 방법으로 개인정보를 지체없이 파기합니다.
- 파기방법 : 회사는 전자적 파일 형태인 경우 복구 및 재생이 불가능한 기술적 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식으로 파기합니다.
 
 
개인정보 보호책임자
회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
[개인정보 보호책임자]
성명 : 이창선 변호사
이메일 : changsun.lee@lfthe-h.com
 
개인정보의 안전성 확보조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
- 관리적 조치 : 내부관리계획 수립‧시행, 정기적 직원 교육 등
- 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
- 물리적 조치 : 전산실, 자료보관실 등의 접근통제
`;

  const policyArray = PRIVACY_POLICY_CONTENT.split('\n \n');

  return (
    <Card
      title={
        <Title level={2}>개인정보 처리방침 (법무법인 더 에이치 황해)</Title>
      }
      style={{ maxWidth: 800, margin: '0 auto' }}
    >
      {policyArray.map((section, index) => (
        <React.Fragment key={index}>
          <Paragraph>
            <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
              {section}
            </pre>
          </Paragraph>
          {index !== policyArray.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Card>
  );
}

export default PrivacyPolicy;
