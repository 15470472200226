import React from 'react';
import { useRecoilState } from 'recoil';
import { userStateAtom } from '../../store/atom';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import refreshIcon from '../../assets/icon/refreshIcon.png';

function ChatHeader({
  title,
  hideRefresh = false,
  onRefresh,
  hideMenu = false,
  ref,
  isDocumentPage = false,
}) {
  const [userState] = useRecoilState(userStateAtom);
  const isAdmin = userState.role === 'admin';
  const { prefix } = useParams();
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  return (
    <HeaderContainer isDocumentPage={isDocumentPage} ref={ref}>
      {isMobile ? (
        // <CustomDrawer userState={userState} isAdmin={isAdmin} prefix={prefix} />
        <div></div>
      ) : (
        <div></div>
      )}
      <Title>{title}</Title>
      <RefreshButton onClick={onRefresh} disabled={hideRefresh}>
        <RefreshIcon src={refreshIcon} alt='refresh' disabled={hideRefresh} />
      </RefreshButton>
    </HeaderContainer>
  );
}

export default ChatHeader;

const HeaderContainer = styled.div`
  padding: 14px 20px;
  width: 100%;
  z-index: 10;
  background-color: ${(props) => (props.isDocumentPage ? '#fff' : '#dde5ea')};
  height: 42px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  @media screen and (min-width: 768px) {
    width: calc(100% - 240px);
  }
`;

const Title = styled.h2`
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 23.8px */
  letter-spacing: -0.34px;
`;

const RefreshButton = styled.button`
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 0;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const RefreshIcon = styled.img`
  width: 20px;
  opacity: ${(props) => (props.disabled ? 0 : 1)};
  color: #fff;
`;
