import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserProfile from '../UserProfile';
import styled from 'styled-components';
import Logo from '../../../assets/logo/theh_logo_V2.png';
import documentIcon from '../../../assets/icon/documentIcon.png';
import { MenuOutlined } from '@ant-design/icons';

export default function Index({
  userState,
  isAdmin,
  prefix,
  children,
  hideHeader,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  if (hideHeader) {
    return <>{children}</>;
  }

  return (
    <DesktopContainer>
      <DesktopHeader isAdmin={isAdmin}>
        <StyledButton marginLeft='12px' opacity='0'>
          <MenuOutlined style={{ fontSize: 20 }} />
        </StyledButton>
        <UserProfile isAdmin={isAdmin} userState={userState} />
      </DesktopHeader>
      <LayoutWrapper>
        {isAdmin && (
          <Sidebar>
            <LogoWrapper>
              <img src={Logo} alt='logo' />
            </LogoWrapper>
            <MenuItem
              onClick={() => navigate(`/${prefix}/admin/document`)}
              isActive={currentPath === `/${prefix}/admin/document`}
            >
              <MenuIcon src={documentIcon} alt='document icon' />
              <span>채권양도 동의서</span>
            </MenuItem>
          </Sidebar>
        )}
        <Content>{children}</Content>
      </LayoutWrapper>
    </DesktopContainer>
  );
}

const DesktopContainer = styled.div`
  width: 100%;
  height: 100vh;
  font-family: 'Pretendard', sans-serif;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const DesktopHeader = styled.header`
  width: 100%;
  top: 0;
  background-color: #00285b;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 48px;
  ${(props) =>
    props.isAdmin &&
    `
    background-color: #fff;
  `}
`;

const StyledButton = styled.button`
  padding: 0;
  font-weight: 400;
  font-family: 'Pretendard', sans-serif;
  font-size: 15px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.color || 'inherit'};
  margin-left: ${(props) => props.marginLeft || '0'};
  opacity: ${(props) => props.opacity || '1'};
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Sidebar = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  padding: 0 20px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 23px;
  img {
    width: 180px;
  }
  /* cursor: pointer; */
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  background: ${(props) => (props.isActive ? '#E7EDF7' : 'transparent')};
  span {
    color: var(--button-gray-menu-a-09, rgba(40, 48, 65, 0.9));
    font-family: 'Pretendard Variable';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }

  span {
    ${(props) =>
      props.isActive &&
      `
      color: var(--button-gray-menu-a-09, rgba(40, 48, 65, 0.90));
font-family: "Pretendard Variable";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
letter-spacing: -0.28px;
    `}
  }

  &:hover {
    background: #e7edf7;
  }
`;

const MenuIcon = styled.img`
  width: 24px;
  margin-right: 10px;
`;

const Content = styled.main`
  flex: 1;
  overflow-y: auto;
`;
