import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { apartmentStateAtom, userStateAtom } from '../../store/atom';
import { useMediaQuery } from 'react-responsive';
import {
  getAllMembers,
  getMemberList,
  getMembersBySearch,
  getSubmissionStatus,
} from '../../apis/account';
import {
  Table,
  Tag,
  Input,
  Button,
  Space,
  Flex,
  Switch,
  ConfigProvider,
  Dropdown,
} from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import styles from '../../styles/Members.module.css';
import styled from 'styled-components';
import downloadIcon from '../../assets/icon/downloadIcon.png';
import { getDocumentDetailApi } from '../../apis/document';
import { getIncidents } from '../../apis/incident';
import { incidentStatusNameEnum } from '../../utils/terminology';
// import arrowDropdownIcon from '../../assets/icon/icon_arrow_drop_down.png';

function AdminIncidents() {
  const [data, setData] = useState([]);
  const { prefix } = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [apartmentState] = useRecoilState(apartmentStateAtom);
  const [userState] = useRecoilState(userStateAtom);
  const isAdmin = userState.role === 'admin';
  const [totalCount, setTotalCount] = useState(0);
  const [currentStatus, setCurrentStatus] = useState();
  const PAGE_SIZE = 12;
  const statusNameEnum = incidentStatusNameEnum;

  const fetchIncidentList = async () => {
    const data = await getIncidents({
      apartmentId: apartmentState.id,
      page: currentPage,
      limit: PAGE_SIZE,
      status: currentStatus,
    });
    console.log('data : ', data);
    setData(data || []);
    setFilteredData(data.incidents || []);
    setTotalCount(data.total || 0);
  };
  useEffect(() => {
    fetchIncidentList();
  }, [currentPage, apartmentState.id, isAdmin, currentStatus]);
  console.log({ filteredData });

  const onPageChange = (page) => {
    setPage(page);
  };
  const navigate = useNavigate();

  const handleFilter = (status) => {
    setCurrentStatus(status);
    console.log('status : ', status);
  };
  const nowrapText = (text) => (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'scroll',
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {text}
    </div>
  );

  const columns = [
    {
      title: nowrapText('연번'),
      dataIndex: 'number',
      key: 'number',
      width: '60px',
      render: (_, data) => (
        <StyledData
          style={{
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {data.id}번
        </StyledData>
      ),
    },
    {
      title: nowrapText('유형'),
      dataIndex: 'type',
      key: 'type',
      width: '80px',
      render: (_, data) => (
        <StyledData
          style={{
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {data.type}
        </StyledData>
      ),
    },
    {
      title: nowrapText('진행 현황'),
      dataIndex: 'idCard',
      key: 'idCard',
      width: '31.25%',
      render: (_, data) => (
        <StyledData
          style={{
            display: 'flex',
            justifyContent: 'center',
            color:
              data.status === 'pending'
                ? 'red'
                : data.status === 'inProgress'
                  ? 'green'
                  : 'blue',
          }}
        >
          {data.status === 'pending'
            ? '시작 전'
            : data.status === 'inProgress'
              ? '진행 중'
              : '진행 완료'}
        </StyledData>
      ),
    },
    {
      title: nowrapText('상세'),
      dataIndex: 'consentForm',
      key: 'consentForm',
      width: '31.25%',
      render: (_, data) => (
        <StyledData
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => {
            navigate(`/${prefix}/admin/incident/${data.id}`);
          }}
        >
          보기
        </StyledData>
      ),
    },
  ];

  // 화면 높이 계산 (scroll)
  const [tableHeight, setTableHeight] = useState(0);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const updateTableHeight = () => {
      if (tableContainerRef.current) {
        const windowHeight = window.innerHeight;
        const containerTop =
          tableContainerRef.current.getBoundingClientRect().top;
        const newHeight = windowHeight - containerTop - 110; // 25px is the bottom margin
        setTableHeight(newHeight);
      }
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => window.removeEventListener('resize', updateTableHeight);
  }, []);

  console.log({ tableHeight });

  // 필터링 (드랍다운)
  const filterItemText = (status) => (
    <p
      style={{
        fontSize: 14,
      }}
      onClick={() => handleFilter(status)}
    >
      {statusNameEnum[status]}
    </p>
  );

  const filterItems = [
    { label: filterItemText('all'), value: 'all' },
    { label: filterItemText('pending'), value: 'pending' },
    { label: filterItemText('inProgress'), value: 'inProgress' },
    { label: filterItemText('completed'), value: 'completed' },
  ];

  return (
    <Container>
      <HeaderBar>
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Dropdown menu={{ items: filterItems }} trigger={['click']}>
            <Space
              style={{
                fontSize: 14,
              }}
            >
              {statusNameEnum[currentStatus] || '전체'}
              <CaretDownOutlined />
            </Space>
          </Dropdown>
        </Space>
      </HeaderBar>
      <div ref={tableContainerRef} style={{ flex: 1, overflow: 'hidden' }}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                footerColor: 'red',
                cellPaddingBlock: '12px',
                cellPaddingInline: '10px',
              },
            },
          }}
        >
          <CustomTable
            columns={columns}
            rowKey='id'
            dataSource={filteredData}
            scroll={{ y: tableHeight }}
            pagination={{
              pageSize: PAGE_SIZE,
              total: totalCount,
              current: currentPage,
              onChange: onPageChange,
              position: ['bottomCenter'],
            }}
            // className={styles.window}
            bordered
          />
        </ConfigProvider>
      </div>
    </Container>
  );
}

export default AdminIncidents;

const Container = styled.div`
  margin-top: 13px;
  width: 100%;
  height: calc(100vh - 22px - 25px); // 상단 마진과 하단 마진을 고려
  display: flex;
  flex-direction: column;
`;

const StyledData = styled.div`
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  display: flex;
`;
const HeaderBar = styled(Flex)`
  margin-bottom: 12px;
`;
const CustomSwitch = styled(Switch)`
  /* background-color: transparent; */
`;
const CustomTable = styled(Table)`
  .ant-pagination-prev {
    border: 1px solid #f1f1f1;
    margin-right: 4px;
  }
  .ant-pagination-next {
    border: 1px solid #f1f1f1;
    margin: 0;
  }
  .ant-pagination-item {
    border-color: #f1f1f1;
    margin-right: 4px;
    &.ant-pagination-item-active {
      border-color: #004f8a;
      background-color: #004f8a;
      a {
        color: #fff;
      }
    }
  }
  /* overflow-y: scroll; */
`;
