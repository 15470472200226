import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { apartmentStateAtom, userStateAtom } from '../../store/atom';
import { useMediaQuery } from 'react-responsive';
import {
  getAllMembers,
  getMemberList,
  getMembersBySearch,
  getSubmissionStatus,
} from '../../apis/account';
import {
  Table,
  Tag,
  Input,
  Button,
  Space,
  Flex,
  Switch,
  ConfigProvider,
  Modal,
  Image,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styles from '../../styles/Members.module.css';
import styled from 'styled-components';
import downloadIcon from '../../assets/icon/downloadIcon.png';
import { getDocumentDetailApi } from '../../apis/document';
import {
  getDefectStatistic,
  getDefectsTotalCounts,
  searchDefects,
} from '../../apis/defects';

export default function AdminDefects() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [apartmentState] = useRecoilState(apartmentStateAtom);
  // console.log({ apartmentState });
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });
  const [userState] = useRecoilState(userStateAtom);
  const isAdmin = userState.role === 'admin';
  const [totalCount, setTotalCount] = useState(0);

  const [searchBuildingBlock, setSearchBuildingBlock] = useState('');
  const [searchUnit, setSearchUnit] = useState('');

  const [submissionStatus, setSubmissionStatus] = useState();

  const [onlyShowNotSubmitted, setOnlyShowNotSubmitted] = useState(false);
  const [defectStatus, setDefectStatus] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const fetchDefectList = async () => {
    const { data } = await getDefectStatistic({
      apartmentId: apartmentState.id,
      page: currentPage,
      onlyShowNotSubmitted,
      searchBuildingBlock,
      searchUnit,
      limit: 8,
    });
    setData(data || []);
    setFilteredData(data.formattedDefects);
    setTotalCount(data.totalCount);

    console.log('호호호호', data);

    // 하자 현황 계산 => 백에서 해야 함
    // const status = await data.formattedDefects.reduce(
    //   (acc, curr) => {
    //     acc.totalDefects += curr.totalCount;
    //     acc.submittedUnits += curr.isSubmitted ? 1 : 0;
    //     return acc;
    //   },
    //   { totalDefects: 0, submittedUnits: 0, totalUnits: data.totalCount }
    // );
    // console.log('하자 현황', status);

    // setDefectStatus(status);
  };

  useEffect(() => {
    fetchDefectList();
  }, [currentPage, apartmentState.id, isAdmin, onlyShowNotSubmitted]);

  const onPageChange = (page) => {
    setPage(page);
  };
  console.log({ totalCount });

  const handleSearch = async () => {
    const lowercasedSearchBuildingBlock = searchBuildingBlock.toLowerCase();
    const lowercasedSearchUnit = searchUnit.toLowerCase();

    const { defects, totalCount } = await searchDefects({
      apartmentId: apartmentState.id,
      buildingBlock: lowercasedSearchBuildingBlock,
      unit: lowercasedSearchUnit,
      onlyShowNotSubmitted: onlyShowNotSubmitted,
    });
    setTotalCount(totalCount);
    setFilteredData(defects);
    setPage(1);
  };

  const showModal = (images) => {
    setSelectedImages(images);
    setIsModalVisible(true);
  };

  const nowrapText = (text) => (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'scroll',
        fontSize: '12px',
        // 가운데 정렬
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {text}
    </div>
  );

  const textColoredBySubmitted = ({
    submittedText,
    notSubmittedText,
    isSubmitted,
  }) => (
    <p
      style={{
        color: isSubmitted ? 'green' : 'red',
      }}
    >
      {isSubmitted ? submittedText : notSubmittedText}
    </p>
  );

  // pdf파일 다운로드
  const onClickDownload = async (documentId) => {
    const res = await getDocumentDetailApi(documentId);
  };

  const [tableHeight, setTableHeight] = useState(0);
  const tableContainerRef = useRef(null);

  const columns = [
    {
      title: nowrapText('동'),
      dataIndex: 'buildingBlock',
      key: 'buildingBlock',
      width: '18.75%',
      render: (item) => (
        <StyledData
          style={{
            maxWidth: '2.5rem',
            overflow: 'scroll',
          }}
        >
          {item}동
        </StyledData>
      ),
    },
    {
      title: nowrapText('호수'),
      dataIndex: 'unit',
      key: 'unit',
      width: '18.75%',
      render: (item) => (
        <StyledData
          style={{
            maxWidth: '3rem',
            overflow: 'scroll',
          }}
        >
          {item}호
        </StyledData>
      ),
    },
    {
      title: nowrapText('하자 접수 여부'),
      dataIndex: 'isSubmitted',
      key: 'isSubmitted',
      render: (isSubmitted) => (
        <StyledData
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {textColoredBySubmitted({
            submittedText: '접수',
            notSubmittedText: '미접수',
            isSubmitted,
          })}
        </StyledData>
      ),
    },
    {
      title: nowrapText('접수 사진 건수'),
      dataIndex: 'totalCount',
      key: 'totalCount',
      render: (totalCount, record) => (
        <StyledData
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {/* <a onClick={() => showModal(record.images)}>{totalCount}</a> */}
          {totalCount > 0 ? (
            <p
              style={{
                color: 'green',
              }}
              onClick={() => showModal(record.images)}
            >
              {totalCount}
            </p>
          ) : (
            <p
              style={{
                color: 'red',
              }}
            >
              -
            </p>
          )}
        </StyledData>
      ),
    },
  ];

  useEffect(() => {
    const updateTableHeight = () => {
      if (tableContainerRef.current) {
        const windowHeight = window.innerHeight;
        const containerTop =
          tableContainerRef.current.getBoundingClientRect().top;
        const newHeight = windowHeight - containerTop - 110; // 25px is the bottom margin
        setTableHeight(newHeight);
      }
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => window.removeEventListener('resize', updateTableHeight);
  }, []);

  console.log({ tableHeight });

  // 전체 하자조사 현황 조회
  const [totalDefectsCountsData, setTotalDefectsCountsData] = useState({});
  const getTotalDefectsCounts = async () => {
    const {
      totlaUnitCount,
      totalDefectUnitCount,
      participationRate,
      totalDefectsCount,
      totalDefectImagesCount,
    } = await getDefectsTotalCounts(apartmentState.id);
    setTotalDefectsCountsData({
      totlaUnitCount,
      totalDefectUnitCount,
      participationRate,
      totalDefectsCount,
      totalDefectImagesCount,
    });
  };
  useEffect(() => {
    getTotalDefectsCounts();
  }, []);

  return (
    <Container>
      <SubmissionStatus>
        <h2>접수여부 및 사진접수 제출 현황</h2>
        <div className='boxWrapper'>
          <div className='statusBox'>
            <h3>접수 세대 수</h3>
            <div className='numbers'>
              <strong>{totalDefectsCountsData.totalDefectUnitCount}</strong>
              <p>건</p>
            </div>
          </div>
          <div className='statusBox'>
            <h3>총 접수율</h3>
            <div className='numbers'>
              <strong>{totalDefectsCountsData.participationRate}</strong>
              <p>%</p>
            </div>
          </div>
        </div>
      </SubmissionStatus>
      <SubmissionStatus>
        {/* <h2>접수여부 및 사진접수 제출 현황</h2> */}
        <div className='boxWrapper'>
          <div className='statusBox'>
            <h3>하자 접수 건수</h3>
            <div className='numbers'>
              <strong>{totalDefectsCountsData.totalDefectsCount}</strong>
              <p>건</p>
            </div>
          </div>
          <div className='statusBox'>
            <h3>사진 접수</h3>
            <div className='numbers'>
              <strong>{totalDefectsCountsData.totalDefectImagesCount}</strong>
              <p>개</p>
            </div>
          </div>
        </div>
      </SubmissionStatus>

      <HeaderBar>
        <Space
          style={{
            marginRight: 28,
          }}
        >
          <Input
            placeholder='동'
            value={searchBuildingBlock}
            onChange={(e) => setSearchBuildingBlock(e.target.value)}
            onPressEnter={handleSearch}
          />
          <Input
            placeholder='호수'
            value={searchUnit}
            onChange={(e) => setSearchUnit(e.target.value)}
            onPressEnter={handleSearch}
          />
          <Button
            type='primary'
            onClick={handleSearch}
            icon={<SearchOutlined />}
          >
            검색
          </Button>
        </Space>
        <Space
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p
            style={{
              fontSize: 10,
              width: 75,
              marginBottom: -5,
            }}
          >
            미접수 세대만 보기
          </p>
          <ConfigProvider
            theme={{
              components: {
                Switch: {
                  handleSize: 20,
                  trackHeight: 14,
                  trackMinWidth: 34,
                  trackPadding: -3,
                  handleShadow: '0 4px 4px 0 rgba(0, 35, 11, 0.2)',
                },
              },
            }}
          >
            <CustomSwitch
              onChange={() => {
                setOnlyShowNotSubmitted(!onlyShowNotSubmitted);
              }}
              style={{
                margin: 0,
              }}
            />
          </ConfigProvider>
        </Space>
      </HeaderBar>

      <div ref={tableContainerRef} style={{ flex: 1, overflow: 'hidden' }}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                footerColor: 'red',
                cellPaddingBlock: '12px',
                cellPaddingInline: '10px',
              },
            },
          }}
        >
          <CustomTable
            columns={columns}
            rowKey='id'
            dataSource={filteredData}
            scroll={{ y: tableHeight }}
            pagination={{
              pageSize: 8,
              total: totalCount,
              current: currentPage,
              onChange: onPageChange,
              position: ['bottomCenter'],
            }}
            className={styles.window}
            bordered
          />
          <StyledModal
            title='하자 사진'
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            width='85%'
            centered={true}
          >
            <Image.PreviewGroup>
              <ImageGrid>
                {selectedImages.map((image, index) => (
                  <Image
                    key={index}
                    width='100%'
                    src={image}
                    alt={`하자 사진 ${index + 1}`}
                  />
                ))}
              </ImageGrid>
            </Image.PreviewGroup>
          </StyledModal>
        </ConfigProvider>
      </div>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 22px;
  width: 100%;
  height: calc(100vh - 22px - 25px); // 상단 마진과 하단 마진을 고려
  display: flex;
  flex-direction: column;
`;

const StyledData = styled.div`
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  display: flex;

  /* overflow: scroll; */
  /* max-width: 2.5rem; */
`;
const StyledTag = styled(Tag)``;

const SubmissionStatus = styled.div`
  &:first-child {
    margin-bottom: 16px;
  }
  margin-bottom: 37px;
  h2 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .boxWrapper {
    display: flex;
    width: 100%;
  }
  .statusBox {
    box-shadow: 0px 0px 20px 0px rgba(139, 160, 177, 0.2);
    width: 100%;
    max-width: 145px;
    margin-right: 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 5px;
    h3 {
      font-size: 16px;
      color: #5f5f5f;
    }
    .numbers {
      display: flex;
      align-items: center;
      height: 56px;
      strong {
        font-size: 40px;
        margin-right: 5px;
        color: var(--main-color);
        font-weight: 700;
      }
      p {
        font-size: 20px;
        margin-top: 10px;
      }
    }
  }
`;

const HeaderBar = styled(Flex)`
  margin-bottom: 11px;
  .ant-switch-inner {
  }
`;
const CustomSwitch = styled(Switch)`
  /* background-color: transparent; */
`;
const CustomTable = styled(Table)`
  .ant-pagination-prev {
    border: 1px solid #f1f1f1;
    margin-right: 4px;
  }
  .ant-pagination-next {
    border: 1px solid #f1f1f1;
    margin: 0;
  }
  .ant-pagination-item {
    border-color: #f1f1f1;
    margin-right: 4px;
    &.ant-pagination-item-active {
      border-color: #004f8a;
      background-color: #004f8a;
      a {
        color: #fff;
      }
    }
  }
  /* overflow-y: scroll; */
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 90vh;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-body {
    flex: 1;
    overflow-y: auto;
  }
`;
const ImageGrid = styled.div`
  display: grid;
  gap: 10px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-template-columns: repeat(3, 1fr);
`;
