import React from 'react';
import { Flex, Button } from 'antd';
import styles from './Message.module.css';
import styled from 'styled-components';

const buttonConfigs = {
  confirm: {
    buttons: [
      { text: '아니오', value: '아니오', className: styles.noButton },
      { text: '예', value: '예', className: styles.yesButton },
    ],
  },
  agreement: {
    buttons: [
      {
        text: '동의하지 않음',
        value: '동의하지 않음',
        className: styles.noButton,
        style: { width: '120px' },
      },
      { text: '동의함', value: '동의', className: styles.yesButton },
    ],
  },
  ownerType: {
    buttons: [
      { text: '법인', value: 'CORPORATION', className: styles.yesButton },
      { text: '개인', value: 'INDIVIDUAL', className: styles.noButton },
    ],
  },
  multipleOwners: {
    buttons: [
      { text: '여러명', value: true, className: styles.yesButton },
      { text: '한 명', value: false, className: styles.noButton },
    ],
  },
};

export default function UserButton({ onClick, type = 'confirm', index = 0 }) {
  const config = buttonConfigs[type] || buttonConfigs.confirm;

  return (
    <Container>
      <Flex align='center' gap={8}>
        {config.buttons.map((button, i) => (
          <StyledButton
            key={i}
            type='text'
            className={`${styles.button} ${button.className}`}
            onClick={() =>
              // onClick(button.value, type === 'agreement' ? index : undefined)
              onClick({
                value: button.value,
                text: button.text,
                index: type === 'agreement' ? index : undefined,
              })
            }
            style={button.style}
          >
            {button.text}
          </StyledButton>
        ))}
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  display: flex;
  min-width: 80px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
`;
