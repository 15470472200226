import React from 'react';
import * as PortOne from '@portone/browser-sdk/v2';
import styled from 'styled-components';

function PaymentTest() {
  const TEST_API_KEY =
    'kMc7x1FDQ7r6umqlcTBSBLcXyiol90YIP2DzTxETz400aN6iowpXUrnJkHCIk1IKhQ3SgOaz5kid8BM7';
  const STORE_ID = 'store-5cf0ceca-5697-49ed-85f0-3625e945aa69';
  const CHANNEL_KEY = 'channel-key-e5f78664-ccc1-4cb8-9139-62942ccf4f50';
  const CLIENT_URL = 'http://localhost:3000';
  // 현재 페이지의 url
  const currentUrl = window.location.href;

  const getPayment = async () => {
    const paymentId = `payment-${crypto.randomUUID()}`;
    const response = await PortOne.requestPayment({
      // Store ID 설정
      storeId: STORE_ID,
      // 채널 키 설정
      channelKey: CHANNEL_KEY,
      paymentId,
      orderName: '나이키 와플 트레이너 2 SD',
      totalAmount: 1000,
      currency: 'CURRENCY_KRW',
      payMethod: 'EASY_PAY',
      bypass: {
        kakaopay: {
          custom_message: '여기가 안내 문구영역입니다.',
        },
      },
      redirectUrl: `${currentUrl}`,
    });
    if (response.code != null) {
      // 오류 발생
      return alert(response.message);
    }
  };

  return (
    <Container>
      <button onClick={getPayment}>Pay</button>
    </Container>
  );
}

export default PaymentTest;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;
