import { publicApi, privateApi } from '.';

export const signup = async (payload) =>
  await publicApi.post(`/account/signup`, payload);

export const getUserInfo = async (prefix) =>
  await privateApi.get(`/account/me/${prefix}`);

export const getMemberList = async (
  page,
  limit,
  apartmentId,
  onlyShowNotSubmitted,
  searchBuildingBlock,
  serachUnit
) => {
  const params = {
    page,
    limit,
    apartmentId,
    onlyShowNotSubmitted,
    searchBuildingBlock,
    serachUnit,
  };

  return await privateApi.get('/account', { params });
};

export const resetConsentForm = async () => {
  return await privateApi.patch('/account/reset');
};

export const getAllMembers = async () => {
  return await (
    await privateApi.get('/account/all')
  ).data;
};

export const getMembersBySearch = async ({
  buildingBlock,
  unit,
  onlyShowNotSubmitted,
  apartmentId,
}) => {
  return await (
    await privateApi.get(
      '/account/search?buildingBlock=' +
        buildingBlock +
        '&unit=' +
        unit +
        '&onlyShowNotSubmitted=' +
        onlyShowNotSubmitted +
        '&apartmentId=' +
        apartmentId
    )
  ).data;
};

export const getSubmissionStatus = async ({ apartmentId }) => {
  return await (
    await privateApi.get(`/account/submission?apartmentId=${apartmentId}`)
  ).data;
};

export const getLawyerAccountsApi = async () => {
  return await (
    await privateApi.get(`/account/lawyer`)
  ).data;
};
