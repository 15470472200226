import { Typography } from 'antd';

import styles from './Message.module.css';

export default function UserMessage({ message }) {
  return (
    <div className={styles.position} is-user={'true'}>
      <div className={styles.messageBox}>
        <Typography className={styles.message} style={{ fontSize: '15px' }}>
          {message}
        </Typography>
      </div>
    </div>
  );
}
