import React from 'react';
import { Layout, Typography, Space, Button } from 'antd';
import { Link } from 'react-router-dom';

const { Footer } = Layout;
const { Text } = Typography;

function PaymentFooter() {
  const CompanyInfo = {
    name: '법무법인 더 에이치 황해',
    businessNumber: '121-81-78634',
    address: '인천광역시 미추홀구 학익소로 60(학익동, ,12 반도빌딩1,2층)',
    phoneNumber: '032-713-8863',
    representative: '천상현',
  };

  const PolicyLink = {
    termsAndConditions: '/terms-and-conditions',
    refundPolicy: '/refund-policy',
    privacyPolicy: '/privacy-policy',
  };

  return (
    <Footer
      style={{
        backgroundColor: '#f0f2f5',
        padding: '12px 50px',
        display: 'flex',
      }}
    >
      <Space
        direction='vertical'
        size='small'
        style={{ display: 'flex', gap: '0px' }}
      >
        <Text strong style={{ padding: 0, fontSize: '10px' }}>
          {CompanyInfo.name}
        </Text>
        <Text style={{ padding: 0, fontSize: '10px' }}>
          사업자등록번호: {CompanyInfo.businessNumber}
        </Text>
        <Text style={{ padding: 0, fontSize: '10px' }}>
          대표자: {CompanyInfo.representative}
        </Text>
        <Text style={{ padding: 0, fontSize: '10px' }}>
          주소: {CompanyInfo.address}
        </Text>
        <Text style={{ padding: 0, fontSize: '10px' }}>
          전화번호: {CompanyInfo.phoneNumber}
        </Text>
      </Space>
      <Space style={{ marginTop: '20px' }}>
        <Link to={PolicyLink.termsAndConditions}>
          <Button type='link' style={{ color: '#0048fa' }}>
            이용약관
          </Button>
        </Link>
        <Link to={PolicyLink.refundPolicy}>
          <Button type='link' style={{ color: '#0048fa' }}>
            환불 규정
          </Button>
        </Link>
        <Link to={PolicyLink.privacyPolicy}>
          <Button type='link' style={{ color: '#0048fa' }}>
            개인정보 처리방침
          </Button>
        </Link>
      </Space>
    </Footer>
  );
}

export default PaymentFooter;
