import 'react-h5-audio-player/lib/styles.css';
import { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import UserMessage from '../../components/Chat/Message/User';
import BotMessage from '../../components/Chat/Message/Bot';
import Loader from '../../components/Chat/Message/Loader';
import { Flex } from 'antd';
import styles from '../../styles/Counsel.module.css';
import { userStateAtom } from '../../store/atom';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import assistantIcon from '../../assets/icon/assistantIcon.png';
import { speechCounsel, streamChatMessages } from '../../apis/counsel';
import ChatHeader from '../../components/common/ChatHeader';
import AudioPlayer from 'react-h5-audio-player';
import recordIcon from '../../assets/icon/recordIcon.png';
import stopIcon from '../../assets/icon/stopIcon.png';
import styled from 'styled-components';
import defaultAudio from '../../assets/audio/defaultAudio.mp3';
import AudioRecorder from 'https://cdn.jsdelivr.net/npm/audio-recorder-polyfill/index.js';

export default function CounselVoice() {
  const [currentMessage, setCurrentMessage] = useState('');
  const [messageList, setMessageList] = useState(
    JSON.parse(localStorage.getItem('voiceCounselList')) || []
  );
  const [isTyping, setIsTyping] = useState(false);
  const [userState] = useRecoilState(userStateAtom);
  const isAdmin = userState.role === 'admin';
  const { prefix } = useParams();
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audioChunks = [];
  const [audioFileList, setFileList] = useState(
    JSON.parse(localStorage.getItem('audioFileList')) || []
  ); // role, audioUrl, content
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const onChange = (e) => {
    setCurrentMessage(e.target.value);
  };

  console.log({ isRecording, isPlaying });

  const handleVoiceRecord = async () => {
    if (isRecording || isLoading || isPlaying) {
      return;
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('이 브라우저는 음성 녹음을 지원하지 않습니다.');
      return;
    }

    try {
      // 권한 요청
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newMediaRecorder = new MediaRecorder(stream);

      newMediaRecorder.onstart = () => {
        setIsRecording(true); // 녹음 시작 상태로 설정
      };

      newMediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      newMediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
        const userMessage = {
          role: 'user',
          audioUrl: URL.createObjectURL(audioBlob),
          content: '',
        };
        setFileList([...audioFileList, userMessage]);

        const formData = new FormData();
        formData.append('dir', 'audio');
        formData.append('audio', audioBlob, 'audioFile.mp3');
        console.log('audioBlob : ', audioBlob);

        audioFileList.forEach((message, index) => {
          formData.append(`messageList[${index}][role]`, message.role);
          formData.append(`messageList[${index}][content]`, message.content);
        });

        localStorage.setItem(
          'audioFileList',
          JSON.stringify([...audioFileList, userMessage])
        );

        try {
          setIsLoading(true);
          const res = await speechCounsel(formData);
          if (res.status === 200) {
            const botMessage = {
              role: 'assistant',
              audioUrl: res.data.ttsAudioLink,
              content: res.data.message,
            };
            userMessage.audioUrl = res.data.sttAudiosLink;
            userMessage.content = res.data.query;
            setFileList([...audioFileList, userMessage, botMessage]);
            localStorage.setItem(
              'audioFileList',
              JSON.stringify([...audioFileList, userMessage, botMessage])
            );
          }
          console.log(res);
        } catch (error) {
          console.error(error);
          // alert(error);
          // alert(error.response?.data || '서버 오류가 발생했습니다.');
        } finally {
          setIsLoading(false);
        }
      };

      newMediaRecorder.start();
      setMediaRecorder(newMediaRecorder);
      console.log('녹음이 시작되었습니다.');
    } catch (error) {
      alert(error.message || error);
      if (error.name === 'NotAllowedError' || error.name === 'SecurityError') {
        alert('마이크 사용 권한이 필요합니다.');
      } else if (error.name === 'NotFoundError') {
        alert('마이크 장치를 찾을 수 없습니다.');
      } else {
        console.error(error);
      }
    }
  };

  // 녹음을 중지하고 API 호출하는 함수
  const stopRecordingAndSend = async () => {
    console.log('stopRecordingAndSend');
    console.log(mediaRecorder);
    if (!mediaRecorder || !isRecording) return;

    mediaRecorder.stop(); // 녹음 중지
    setIsRecording(false); // 녹음 상태 업데이트
  };

  const onRefresh = async () => {
    setMessageList([]);
    setFileList([]);
    localStorage.removeItem('audioFileList');
    localStorage.removeItem('voiceCounselList');
  };

  // 화면 맨 아래로 스크롤
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    if (!isTyping && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isTyping]);
  useEffect(() => {
    scrollToBottom();
  }, [audioFileList]);

  // 마이크 접속 권한 허용시 autoplay 중복재생 방지
  const [isInitialRender, setIsInitialRender] = useState(true);

  return (
    <Container justify='space-between' vertical gap={12}>
      <ChatHeader title={'대화형 AI 법률 상담'} onRefresh={onRefresh} />
      <Window data-ismobile={isMobile}>
        <div className={styles.botArea}>
          <div className={styles.nameTag}>
            <img
              src={assistantIcon}
              alt='더에이치'
              className={styles.assistantIcon}
            />
          </div>
          <div className={styles.botWrapper}>
            <CustomAudioPlayer
              autoPlay={audioFileList.length === 0 && isInitialRender === true}
              preload='metadata'
              src={defaultAudio}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              showFilledVolume={false}
              showSkipControls={false}
              hasDefaultKeyBindings={false}
              layout='horizontal-reverse'
              customProgressBarSection={['DURATION']}
              customVolumeControls={[]}
              customAdditionalControls={[]}
              className='botAudioPlayer'
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onEnded={() => {
                setIsPlaying(false);
                handleVoiceRecord();
                setIsInitialRender(false);
              }}
            />
            <BotMessage
              message={`안녕하세요, 더에이치입니다.

어떤 상담을 원하시나요?`}
            />
          </div>
        </div>
        {audioFileList.length > 0 &&
          audioFileList.map((file, i) => (
            <div key={i}>
              {file.role === 'assistant' ? (
                // AI
                <div className={styles.botArea}>
                  <div className={styles.nameTag}>
                    <img
                      src={assistantIcon}
                      alt='더에이치'
                      className={styles.assistantIcon}
                    />
                  </div>
                  <div className={styles.botWrapper}>
                    <CustomAudioPlayer
                      autoPlay={Number(audioFileList.length - 1) === Number(i)}
                      // ref={(el) => (audioRefs.current[i] = el)}
                      preload='metadata'
                      src={file.audioUrl}
                      showJumpControls={false}
                      showDownloadProgress={false}
                      showFilledProgress={false}
                      showFilledVolume={false}
                      showSkipControls={false}
                      hasDefaultKeyBindings={false}
                      layout='horizontal-reverse'
                      customProgressBarSection={['DURATION']}
                      customVolumeControls={[]}
                      customAdditionalControls={[]}
                      className='botAudioPlayer'
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                      onEnded={() => setIsPlaying(false)}
                      // onLoadedData={() => {
                      //   if (audioFileList.length - 1 === i) {
                      //     audioRefs.current[i].audio.current.play();
                      //   }
                      // }}
                    />
                    {file.content && file?.content.length > 0 && (
                      <BotMessage message={file.content} />
                    )}
                  </div>
                </div>
              ) : (
                // 유저
                <div className={styles.userArea}>
                  <div className={styles.userWrapper}>
                    <CustomAudioPlayer
                      autoPlayAfterSrcChange={false}
                      preload='metadata'
                      src={file.audioUrl}
                      showJumpControls={false}
                      showDownloadProgress={false}
                      showFilledProgress={false}
                      showFilledVolume={false}
                      showSkipControls={false}
                      hasDefaultKeyBindings={false}
                      layout='horizontal-reverse'
                      customProgressBarSection={['DURATION']}
                      customVolumeControls={[]}
                      customAdditionalControls={[]}
                      className='userAudioPlayer'
                    />
                    {/* {file.content && file?.content.length > 0 && (
                        <div style={{ width: '25rem' }}>
                          <UserMessage message={file.content} />
                        </div>
                      )} */}
                  </div>
                </div>
              )}
            </div>
          ))}
        {isLoading && <Loader />}
        <div
          ref={messagesEndRef}
          style={{
            margin: 0,
            padding: 0,
          }}
        />
      </Window>
      <Footer align='center' justify='center'>
        {isRecording ? (
          <img
            src={stopIcon}
            className={styles.voiceIcon}
            onClick={stopRecordingAndSend}
          />
        ) : (
          <img
            src={recordIcon}
            className={styles.voiceIcon}
            onClick={handleVoiceRecord}
          />
        )}
      </Footer>
    </Container>
  );
}

const CustomAudioPlayer = styled(AudioPlayer)`
  width: 110px;
  font-size: 15px;
  border-radius: 8px;
  padding: 5px;
  box-shadow: none;
  .rhap_progress-section {
    padding-right: 7px;
  }
  &.botAudioPlayer {
    background-color: #fff;
    .rhap_main-controls {
    }

    .rhap_button-clear {
      color: #5f5f5f;
    }
  }
  &.userAudioPlayer {
    background-color: var(--main-color);
    .rhap_button-clear {
      color: #fff;
    }
    .rhap_time {
      color: #fff;
    }
  }
`;

const Container = styled.div`
  overflow-y: hidden;
  background-color: #dde5ea;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  /* margin-top: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* transition: 0.1s; */
`;
const Footer = styled(Flex)`
  margin: 0;
  box-sizing: border-box;
  padding: 16px 20px;
  height: 80px;
  background-color: #abbecd;
  width: 100%;
  z-index: 9;
  /* position: fixed;
  bottom: 0; */
  @media screen and (min-width: 768px) {
    width: calc(100% - 240px);
  }
`;
const Window = styled(Flex)`
  /* background-color: red; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;

  overflow: auto;
  padding: 32px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;
