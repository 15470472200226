import React from 'react';
import { Result, Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

function PaymentSuccess() {
  return (
    <Container>
      <Result
        status='success'
        title='결제가 완료되었습니다.'
        subTitle={
          <StyledParagraph>
            3영업일 내로 변호사님께서 연락드릴 예정입니다.
            <br />
            감사합니다.
          </StyledParagraph>
        }
      />
    </Container>
  );
}

export default PaymentSuccess;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  font-size: 16px;
`;
