import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { apartmentStateAtom, userStateAtom } from '../store/atom';
import { useMediaQuery } from 'react-responsive';
import {
  getAllMembers,
  getMemberList,
  getMembersBySearch,
} from '../apis/account';
import { Flex, Table, Tag, Input, Button, Space } from 'antd';
import { ConsoleSqlOutlined, SearchOutlined } from '@ant-design/icons';
import styles from '../styles/Members.module.css';
import ChatHeader from '../components/common/ChatHeader';
import styled from 'styled-components';

export default function Members() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [apartmentState] = useRecoilState(apartmentStateAtom);
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });
  const [userState] = useRecoilState(userStateAtom);
  const isAdmin = userState.role === 'admin';
  const [totalCount, setTotalCount] = useState(0);

  const [searchBuildingBlock, setSearchBuildingBlock] = useState('');
  const [searchUnit, setSearchUnit] = useState('');

  useEffect(() => {
    // if (!isAdmin) return;
    (async () => {
      if (isAdmin) {
        const { data } = await getMemberList(
          currentPage,
          20,
          apartmentState.id
        );
        setData(data || []);
        setFilteredData(data.users || []);
        setTotalCount(data.totalCount);
      }
    })();
  }, [currentPage, apartmentState.id, isAdmin]);

  // console.log({ userState });

  const onPageChange = (page) => {
    setPage(page);
  };

  const handleSearch = async () => {
    const lowercasedSearchBuildingBlock = searchBuildingBlock.toLowerCase();
    const lowercasedSearchUnit = searchUnit.toLowerCase();
    const { users, totalCount: count } = await getMembersBySearch({
      buildingBlock: lowercasedSearchBuildingBlock,
      unit: lowercasedSearchUnit,
    });

    setTotalCount(count);
    setFilteredData(users);
  };

  const handleReset = () => {
    setSearchBuildingBlock('');
    setSearchUnit('');
    setFilteredData(data.users);
    console.log({ data });
    setTotalCount(data.totalCount);
  };

  const nowrapText = (text) => (
    <div style={{ whiteSpace: 'nowrap', overflow: 'scroll' }}>{text}</div>
  );

  const columns = [
    {
      title: '동',
      dataIndex: 'buildingBlock',
      key: 'buildingBlock',
      width: '20%',
    },
    {
      title: '호수',
      dataIndex: 'unit',
      key: 'unit',
      width: '20%',
    },
    {
      title: nowrapText('신분증 제출 여부'),
      dataIndex: 'idCard',
      key: 'idCard',
      width: '30%',
      render: (_, data) => (
        <StyledData>
          <Tag color={data.idCard ? 'green' : 'red'}>
            {data.idCard ? '제출' : '미제출'}
          </Tag>
        </StyledData>
      ),
    },
    {
      title: nowrapText('동의서 제출 여부'),
      dataIndex: 'consentForm',
      key: 'consentForm',
      width: '30%',
      render: (_, data) => (
        <StyledData>
          <Tag color={data.consentForm ? 'green' : 'red'}>
            {data.consentForm ? '제출' : '미제출'}
          </Tag>
        </StyledData>
      ),
    },
  ];

  return (
    <Flex justify='flex-start' vertical className={styles.container}>
      <ChatHeader
        title={'동의서 및 신분증 제출 내역' + (isAdmin ? '(관리자)' : '')}
        hideRefresh={true}
        isDocumentPage={true}
      />

      {isAdmin && (
        <div className={styles.searchContainer}>
          <Space
            style={{
              padding: '0 8px 8px',
            }}
          >
            <Input
              placeholder='동'
              value={searchBuildingBlock}
              onChange={(e) => setSearchBuildingBlock(e.target.value)}
              onPressEnter={handleSearch}
              // style={{ width: 200 }}
            />
            <Input
              placeholder='호수'
              value={searchUnit}
              onChange={(e) => setSearchUnit(e.target.value)}
              onPressEnter={handleSearch}
              // style={{ width: 200 }}
            />
            <Button
              type='primary'
              onClick={handleSearch}
              icon={<SearchOutlined />}
            >
              검색
            </Button>
            <Button onClick={handleReset}>초기화</Button>
          </Space>
        </div>
      )}

      <Table
        columns={columns}
        rowKey='id'
        dataSource={isAdmin ? filteredData : [userState]}
        // dataSource={filteredData}
        pagination={{
          pageSize: 20,
          total: totalCount,
          current: currentPage,
          onChange: onPageChange,
        }}
        className={styles.window}
        // style={{
        //   height: 1000,
        // }}
        scroll={{
          y: 380,
        }}
      />
    </Flex>
  );
}

const StyledData = styled.div`
  white-space: nowrap;
  /* 가운데 정렬 */
  text-align: center;
`;
