import axios from 'axios';

export const publicApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const privateApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessTokenV2')}`,
  },
});

privateApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessTokenV2');
  config.headers.Authorization = 'Bearer ' + token;

  return config;
});

privateApi.interceptors.response.use(
  (response) => {
    // 응답 성공 시 로직
    return response;
  },
  (error) => {
    // 에러 처리 로직
    if (error.response) {
      // 서버가 응답을 했지만 상태 코드가 2xx 범위가 아닌 경우
      console.error('서버 응답 에러:', error.response.data);
    } else if (error.request) {
      // 요청이 만들어졌지만 응답을 받지 못한 경우
      console.error('응답 없음:', error.request);
    } else {
      // 요청을 설정하는 중에 에러가 발생한 경우
      console.error('요청 설정 에러:', error.message);
    }
    return Promise.reject(error);
  }
);
