import { privateApi } from './index';

export const getDefectImages = async () => await privateApi.get('/defect');

export const getDefectStatistic = async ({
  page,
  limit,
  apartmentId,
  onlyShowNotSubmitted,
  searchBuildingBlock,
  serachUnit,
}) => {
  return await privateApi.get(`/defect/statistic/${apartmentId}`, {
    params: {
      page,
      limit,
      onlyShowNotSubmitted,
      searchBuildingBlock,
      serachUnit,
    },
  });
};

export const searchDefects = async ({
  apartmentId,
  buildingBlock,
  unit,
  onlyShowNotSubmitted,
}) => {
  return await (
    await privateApi.get(`/defect/search/${apartmentId}`, {
      params: { buildingBlock, unit, onlyShowNotSubmitted },
    })
  ).data;
};

export const getDefectsTotalCounts = async (apartmentId) => {
  return await (
    await privateApi.get(`/defect/total/${apartmentId}`)
  ).data;
};
