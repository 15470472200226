import { Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import styles from './Message.module.css';
import rehypeRaw from 'rehype-raw';

export default function BotMessage({
  message,
  isDocumentPage = false,
  dataIsBot = true,
}) {
  return (
    <Container>
      <MessageBox>
        <div className={`${styles.markdownWrapper}`}>
          {/* <ReactMarkdown remarkPlugins={[remarkGfm]}>{message}</ReactMarkdown> */}
          <ReactMarkdown
            children={message}
            components={{
              p: ({ node, ...props }) => (
                <p className={styles.paragraph} {...props} />
              ),
              strong: ({ node, ...props }) => (
                <strong className={styles.strong} {...props} />
              ),
            }}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
      </MessageBox>
    </Container>
  );
}

const MessageBox = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0px 8px 8px 8px;
  background: #fff;
  word-break: keep-all;
  width: 100%;

  .highlight-blue {
    background: var(--GR_01, linear-gradient(180deg, #5d7cf6 0%, #4e3cf4 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.32px;
  }
  .${styles.markdownWrapper} {
    width: 100%;
  }

  .${styles.markdownWrapper} p {
    word-break: keep-all;
    white-space: pre-wrap;
  }

  p {
    word-break: keep-all !important;
    white-space: pre-wrap !important;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledMarkdown = styled(ReactMarkdown)`
  color: #272727;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
`;
